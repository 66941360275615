import React from 'react';
import styled from '@emotion/styled';

import { Header, HeaderInfo } from './styled'

export function ContentLayout({ children, title, description, buttons = [] }) {

  return (
    <Container>

      <Wrapper>

        <Header>
          <HeaderInfo>
            <h2>{ title }</h2>
            <h5>{description}</h5>
          </HeaderInfo>

          <ButtonGroup>
            {!!buttons.length && buttons.map((e, i) => <Button key={i} onClick={e.onClick}>{e.label}</Button>)}
          </ButtonGroup>
        </Header>

        <hr />

        <div>
          { children }
        </div>

      </Wrapper>

    </Container>
  );
}

const Container = styled.div`
margin: 8px 16px;
border-radius: 8px;
background-color: rgb(255, 255, 255);
`

const Wrapper = styled.div`
min-height: 500px;
padding: 16px;

hr {
  border-color: rgba(0,0,0,0.15);
}
`

const ButtonGroup = styled.div`
display: flex;
`

const Button = styled.div`
cursor: pointer;
padding: 8px 14px;
border-radius: 8px;
border: 1px solid rgba(0,0,0,0.15);
width: fit-content;
margin-left: 8px;
`