import styled from '@emotion/styled'
import { useEffect, useState } from 'react';
import { HOST_URL } from '../shared/env'
import axios from 'axios';
import Graph from 'react-graph-vis'
import Swal from 'sweetalert2'

const randomColor = () => {
  const red = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
  const green = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
  const blue = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
  return `#${red}${green}${blue}`;
}

export function RecomGraph() {

  const [graph, setGraph] = useState({
    nodes: [
    ],
    edges: [
    ]
  })

  const options = {
    layout: {
      hierarchical: {
        enabled: true,
        levelSeparation: 250,
        direction: 'DU',
        sortMethod: 'hubsize'
      },
    },
    physics: {
      enabled: false
    },
    nodes: {
      shape: 'box'
    },
    edges: {
      smooth: false,
      color: '#000000'
    }
  };

  const events = {
    select: async (event) => {
      let { nodes } = event

      let email_addr = nodes[0]

      const { data: { result } } = await axios.post(`${HOST_URL}/app/appAdminPointUserInfo`, {
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        email_addr
      })

      if (Object.keys(result).length) {

        Swal.fire({
html: `
<p>이메일 : ${email_addr}</p>
<p>닉네임 : ${result.user_nick_nm}</p>
<p>이름 : ${result.user_nm}</p>
<p>번호 :${result.phone_num}</p>
<p>지역 : ${result.area}</p>
<p>성별 : ${result.sex}</p>
<p>생일 : ${result.birth_day}</p>
<p>포인 : ${result.point_sum}</p>
<p>코인 : ${result.coin_sum}</p>
<p>마케팅 알람동의 : ${result.mkting_alm_yn} </p>
`
  ,
          imageUrl: result.photo || '',
          imageWidth: 200,
          imageHeight: 200,
          confirmButtonText: '확인',
          confirmButtonColor: '#FFCE3E'
        })

        console.log(result)

      }

    }
  };  

  const [enbed, setEnbed] = useState(false)

  useEffect(() => {

    if (enbed) {

      (async () => {

        const { data: { result } } = await axios.post(`${HOST_URL}/app/appAdminUserRecomGraphList`, {
          lang: 'ko',
          token: localStorage.getItem('admin_token'),
          mst_cd_id: 'M0009'
        })

        setGraph(e => ({
          nodes: result.users.map((e, i) => ({ id: e.email_addr, label: e.email_addr, title: e.user_nick_nm, color: randomColor() })),
          edges: result.users.map((e, i) => !e.recom_email_addr ? '' : ({ to: e.email_addr, from: e.recom_email_addr })).filter(e => e)
        }))

      })()        

    }

    setEnbed(true)

  }, [enbed])

  return (<Container>

    { !!graph.nodes.length ? <Graph
      graph={graph}
      options={options}
      events={events}
      style={{ height: '800px', border: '1px solid red' }}
    /> : <p>로딩중입니다..<br/><span><small>(5~10초 소요될 수 있습니다)</small></span><br /></p> }

  </Container>)
}

const Container = styled.div``