import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import styled from '@emotion/styled';
import axios from 'axios';

export function Table({ 
  apiUrl = '',
  listName = '',
  apiType = '',
  apiSeq = '',
  countName = '',
  columns = [],
  searchs = [],
  onClickRow, 
  onSelectRows,
  paginationServer = true, 
  selectableRows = true,
  selectableRowsSingle = true
}) {

  const location = useLocation()
  const navigate = useNavigate()

  const token = localStorage.getItem('admin_token')

  const [count, setCount] = useState(0)
  const [data, setData] = useState([])

  const [searchText, setSearchText] = useState('')
  const [searchType, setSearchType] = useState('')
  const [initialedData, setInitialedData] = useState(false)
  const [defaultPage, setDefaultPage] = useState(1)

  useEffect(() => {

    fetchAPI()
  }, [apiType])

  const fetchAPI = async (page) => {

    const searchParams = new URLSearchParams(location.search)

    page = page || +searchParams.get('page') || 1
    const search_type = searchs.length && (searchType || searchParams.get('search_type') || searchs[0].value)
    const search_text = searchText || searchParams.get('search_text') || ''

    let payload = {lang: 'ko', token, page: page, limit: 10, type: apiType }

    if (apiSeq) payload.seq = apiSeq

    const { data : { result } } = await axios.post(apiUrl, {
      ...payload,
      ...(search_text ? { search_type, search_text } : {})
    })

    searchParams.set('page', page)

    if (search_text) {
      searchParams.set('search_type', search_type)
      searchParams.set('search_text', search_text)
    }

    const newSearch = searchParams.toString()
    navigate(`${location.pathname}?${newSearch}`)        

    setCount(result[countName])
    setData(result[listName])
  }

  const onPage = async (page) => {

    if (!initialedData) return

    await fetchAPI(page)
  }

  const onSearch = async () => {

    if(!searchText) {
      navigate(location.pathname)
      window.location.reload()
      return
    }

    await fetchAPI(1)
  }  

  useEffect(() => {

    const searchParams = new URLSearchParams(location.search)

    setDefaultPage(+searchParams.get('page') || 1)
    setSearchText(searchParams.get('search_text') || '')
    searchs.length && setSearchType(searchType || searchParams.get('search_type') || searchs[0].value);

    (async () => {
      await fetchAPI()

      setInitialedData(true)
    })()

  }, [])

  return (
    <div>

      { !!searchs.length && <SearchBox>
          <SearchType value={searchType} onChange={(e) => (setSearchType(e.target.value), setSearchText(''))}>
            {searchs.map((e, i) => <option key={i} value={e.value}>{e.label}</option>)}
          </SearchType>
          {searchs.find(e => e.value === searchType && e.options) 
          ? 
          <SearchSelect onChange={(e) => setSearchText(e.target.value)}>
            <option>===================</option>
            {searchs.find(e => e.value === searchType && e.options).options.map((e, i) => <option key={i} value={e.value}>{e.label}</option>)}
          </SearchSelect>
          :
          <SearchInput value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyDown={(e) => e.key === 'Enter' && onSearch()} />
          }
        <SearchSubmit onClick={() => onSearch()}>조회</SearchSubmit>
      </SearchBox>}

      <DataTable
        /* row */
        data={data}
        columns={columns.map((e, i) => ({ ...e, maxWidth: i === 0 ? '50px' : '250px' }))}
        onRowClicked={onClickRow}
        /* selectable */
        selectableRows={selectableRows}
        selectableRowsSingle={selectableRowsSingle}
        /* paging */
        pagination
        paginationPerPage={10}
        paginationDefaultPage={defaultPage}
        paginationServer={paginationServer}
        paginationTotalRows={count}
        paginationComponentOptions={{ noRowsPerPage: true }}
        /* callback */
        onChangePage={onPage}
        onSelectedRowsChange={({ selectedRows }) => onSelectRows && onSelectRows(selectedRows)}
        /* custom */
        noDataComponent={<div style={{paddingTop: '16px'}}>조회되는 내역이 없습니다</div>}
        customStyles={{
          rows: {
            style: {
                ...(onClickRow && { cursor: 'pointer'})
            }
          }
        }}
      />
    </div>
  );
}

const SearchBox = styled.div`
display: flex;
justify-content: flex-end;
`

const SearchType = styled.select`
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
cursor: pointer;
padding: 8px 14px;
border-radius: 8px;
text-decoration: none;
outline: none;
border: 1px solid rgba(0,0,0,0.15);
width: fit-content;
margin-right: 4px;
`

const SearchInput = styled.input`
width: 50%;
text-decoration: none;
outline: none;
padding: 0 8px;
`

const SearchSelect = styled.select`
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
width: 50%;
text-decoration: none;
outline: none;
padding: 0 8px;
`

const SearchSubmit = styled.div`
cursor: pointer;
padding: 8px 14px;
border-radius: 8px;
border: 1px solid rgba(0,0,0,0.15);
width: fit-content;
margin-left: 8px;
`