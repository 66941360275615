import React from 'react';
import ReactModal from 'react-modal';

export function Modal({ children, isOpen, onClose }) {

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          top: '0',
          left: '0',
          padding: 0,
          minWidth: '100%',
          minHeight: '100%',
          overflow: 'hidden'
        },
      }}
    >
      {children}
    </ReactModal>
  );
}