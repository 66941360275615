import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { Modal } from '../shared/Modal';
import { ModalContent } from '../shared/ModalContent';
import { HOST_URL } from '../shared/env'
import { convertDatetime } from '../shared/date';

export function BoardQna() {

  const navigate = useNavigate()

  const [item, setItem] = useState({
    subject: '',
    content: ''
  })
  const [opend, setOpend] = useState(false)

  const updateQna = async (qna) => {

    console.log(qna)

    await axios.post(`${HOST_URL}/app/appQnaReply`, {
      lang: 'ko',
      token: localStorage.getItem('admin_token'),
      qna_seq : item.qna_seq,
      reply_content: qna.reply_content
    })

    setOpend(false)
    navigate(0)

  }

  return (
    <ContentLayout 
      title='문의사항 관리'
      description='문의사항 관리 페이지입니다'
      buttons={[
      ]}
    >

      <Table
        apiUrl={`${HOST_URL}/app/appAdminQnaList`}
        listName='qnas'
        countName='totalCount'
        columns={[
          { name: '문의사항 번호', selector: row => row.qna_seq },
          { name: '문의 제목', selector: row => row.subject },
          { name: '문의 내용', selector: row => row.content },
          { name: '문의자', selector: row => row.crt_usr.user_nm },
          { name: '문의자 이메일', selector: row => row.crt_usr_id },
          { name: '등록일', selector: row => convertDatetime(row.crt_dt) },
          { name: '답변 여부', selector: row => row.reply_status },
          { name: '답변 내용', selector: row => row.reply_content },
          { name: '답변 등록일', selector: row => row.reply_crt_dt }
        ]}
        searchs={[
          { label: '이메일', value: 'email_addr' },
          { label: '제목', value: 'subject' },
          { label: '내용', value: 'content' }
        ]}
        onClickRow={(row) => {
          setItem(row)
          setOpend(true)
        }}        
        onSelectRows={(rows) => setItem(rows.length ? rows[0] : undefined)}
        selectableRows={false}
      />
      <Modal
        isOpen={opend}
        onClose={() => setOpend(false)}
      >
        <ModalContent 
          title='문의사항 관리' 
          description='문의사항 관리 페이지입니다'
          data={item}
          columns={ [
            { label: '문의자 이름', name: 'crt_usr.user_nm', type: 'text'},
            { label: '문의자이메일', name: 'crt_usr_id', type: 'text'},
            { label: '문의 제목', name: 'subject', type: 'text' },
            { label: '문의 내용', name: 'content', type: 'textarea' },
            { label: '답변 내용', name: 'reply_content', type: 'textarea', editable: true}
          ]}
          onSubmit={updateQna}
          onClose={() => setOpend(false)}
        />        
      </Modal>
    </ContentLayout>
  );
}
