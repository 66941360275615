import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { Modal } from '../shared/Modal';
import { ModalContent } from '../shared/ModalContent';
import { HOST_URL } from '../shared/env'
import { convertDatetime } from '../shared/date';

export function BoardNotice() {

  const navigate = useNavigate()

  const [item, setItem] = useState({
    subject: '',
    content: ''
  })
  const [opendNewNotice, setOpendNewNotice] = useState(false)
  const [opendEditNotice, setOpendEditNotice] = useState(false)

  const addNotice = async (notice) => {

    await axios.post(`${HOST_URL}/app/appFaqRegist`, {
      lang: 'ko',
      token: localStorage.getItem('admin_token'),
      faq_type : "noti",
      subject: notice.subject,
      content: notice.content
    })

    setOpendNewNotice(false)
    navigate(0)

  }

  const editNotice = async (notice) => {

    await axios.post(`${HOST_URL}/app/appAdminFaqUpdate`, {
      lang: 'ko',
      faq_seq: item.faq_seq,
      faq_type: 'noti',      
      token: localStorage.getItem('admin_token'),
      subject: notice.subject,
      content: notice.content
    })

    setOpendEditNotice(false)
    navigate(0)
    
  }

  return (
    <ContentLayout 
      title='공지사항 관리'
      description='공지사항 관리 페이지입니다'
      buttons={[
        { label: '등록', onClick: () => setOpendNewNotice(true)}
      ]} 
    >

      <Table
        apiUrl={`${HOST_URL}/app/appAdminNoticeList`}
        listName='notices'
        countName='totalCount'
        columns={[
          { name: '공지사항 번호', selector: row => row.faq_seq },
          { name: '제목', selector: row => row.subject },
          { name: '내용', selector: row => row.content },
          { name: '등록자', selector: row => row.crt_usr.user_nm },
          { name: '등록자 이메일', selector: row => row.crt_usr_id },
          { name: '등록일', selector: row => convertDatetime(row.crt_dt) },
        ]}
        searchs={[
          { label: '이메일', value: 'email_addr' },
          { label: '제목', value: 'subject' },
          { label: '내용', value: 'content' }
        ]}
        onClickRow={(row) => {
            setItem(row)
            setOpendEditNotice(true)
        }}        
        selectableRows={false}
      />
      <Modal
        isOpen={opendNewNotice}
        onClose={() => setOpendNewNotice(false)}
      >
        <ModalContent 
          title='공지사항 등록' 
          description='공지사항 등록 페이지입니다'
          data={item}
          columns={ [
            { label: '제목', name: 'subject', type: 'text', editable: true },
            { label: '내용', name: 'content', type: 'textarea', editable: true }
          ]}
          onSubmit={addNotice}
          onClose={() => setOpendNewNotice(false)}
          submitLabel='등록'
        />        
      </Modal>

      <Modal
        isOpen={opendEditNotice}
        onClose={() => {
          setOpendEditNotice(false)
          setItem({}) 
        }}
      >
        <ModalContent 
          title='공지사항 수정' 
          description='공지사항 수정 페이지입니다'
          data={item}
          columns={ [
            { label: '제목', name: 'subject', type: 'text', editable: true },
            { label: '내용', name: 'content', type: 'textarea', editable: true }
          ]}
          onSubmit={editNotice}
          onClose={() => {
            setOpendEditNotice(false)
            setItem({})
          }}
          submitLabel='수정'
        />        
      </Modal>
    </ContentLayout>
  );
}
