import styled from '@emotion/styled';
import React, { useState } from 'react';

export function FormDropDownBox({ opend, doned = false, text, children, onDelete, onOpend }) {

  return (
    <Container>
        <Header>
          <Info>
            <InfoIconBox>
              { doned && !opend && <img src='/images/check-solid.svg' /> }
            </InfoIconBox>
            <h4>
              { !opend && text}
            </h4>
          </Info>
          <Icons>
            <Icon onClick={onOpend}>{opend ? '닫기' : '열기'}</Icon>
            <Icon onClick={onDelete}>삭제</Icon>
          </Icons>
        </Header>
        <div style={{ display: opend ? 'block' : 'none'}}>
            {children}
        </div>
    </Container>
  );
}

const Container = styled.div`
border-radius: 5px;
border: .5px solid rgba(0,0,0,0.1);
padding: 10px;
margin-bottom: 10px;
`

const Header = styled.div`
display: flex;
justify-content: space-between;
align-items: center;

h4 {
  font-weight: 500;
  margin: 0;
  padding: 0;
}
`

const Info = styled.div`
display: flex;
`

const InfoIconBox = styled.div`
width: 25px;
display: flex;
align-items: center;

img {
  width: 18px;
}
`

const Icons = styled.div`
display: flex;
`

const Icon = styled.div`
border-radius: 5px;
background-color: rgb(235, 235, 235);
padding: 6px;
cursor: pointer;
margin-left: 8px;
width: 35px;
text-align: center;
`