import React, { useEffect, useState } from 'react';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { HOST_URL } from '../shared/env'
import axios from 'axios';
import { Modal } from '../shared/Modal';
import { ModalTable } from '../shared/ModalTable';
import { convertNumberComma } from '../shared/comma';

export function AdTotal() {

  const [item, setItem] = useState(undefined)
  const [opend, setOpend] = useState(false)
  const [ad_codes, setAdCodes] = useState([])

  const [apiType, setApiType] = useState('daily')

  useEffect (() => {

    (async () => {

      const respAdCodes = await axios.post(`${HOST_URL}/app/appCodeList`, {
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        mst_cd_id: 'M0001'
      })

      setAdCodes(respAdCodes.data.result)  
    })()  

  }, [])    

  return (
    <ContentLayout 
      title='광고실적 집계' 
      description='광고실적 집계 페이지입니다'
    >
      <Table
        apiUrl={`${HOST_URL}/app/appAdminAdList`}
        listName='ads'
        countName='totalCount'
        columns={[
          { name: '광고번호', selector : row => row.ad_seq },
          { name: '광고유형', selector: row => ad_codes.length && ad_codes.find(e => e.dtl_cd_id === row.ad_type).dtl_cd_nm },  
          { name: '광고명', selector: row => row.ad_subj },
          { name: '등록자', selector: row => row.reg_email_addr2.user_nm },
          { name: '광고노출수', selector: row => convertNumberComma(row.ad_view_cnt)},
          { name: '광고클릭수', selector: row => convertNumberComma(row.ad_click_cnt)}
        ]}
        onClickRow={(row) => {
          if(row) {
            setItem(_ => row)
            setOpend(true)
          }
        }}
        selectableRows={false}
      />

      <Modal
        isOpen={opend}
        onClose={() => setOpend(false)}
      >

        <ModalTable
          title='광고실적 집계' 
          description='광고실적 집계 페이지입니다'
          buttons={[
            { label: apiType === 'daily' ? '월별 집계' : '일별 집계', onClick: () => setApiType(apiType === 'daily' ? 'monthly' : 'daily') }
          ]}
          data={item}
          onClose={() => setOpend(false)}
          apiUrl={`${HOST_URL}/app/appAdminAdTotalList`}
          apiType={apiType}
          listName='totals'
          countName='totalCount'
          columns={[
            { name: '일자', selector: row => row.date },
            { name: '광고노출수', selector: row => row.view },
            { name: '광고클릭수', selector: row => row.click },
          ]}
          selectableRows={false}
          paginationServer={false}          
        />

      </Modal>
    </ContentLayout>
  );
}
