import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios'
import styled from '@emotion/styled'
import { HOST_URL } from '../shared/env'

export function Login() {

  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  console.log('Login render');

  const onAuth = async (entrySite) => {

    window.location.href = `${HOST_URL}/oauth2/${entrySite}/auth/admin`

  }

  useEffect(() => {

    const access_token = searchParams.get('access_token')
    const entry_site = searchParams.get('entry_site')

    if (access_token) {
      console.log('access_token', access_token)
      console.log('entry_site', entry_site)
      setSearchParams('');

      (async () => {

        const { data : { result } } = await axios.post(`${HOST_URL}/app/appLogin`, {
          entry_site,
          access_token
        })

        const token = result.token

        if (result.role_nm !== 'ROLE_ADMIN') {
          alert('어드민이 아닙니다')
          return;
        }

        if (token) {
          localStorage.setItem('admin_token', token)
          navigate('/')
        }


      })();
      
    }

    return () => {

    };
  }, [searchParams]);

  return (
    <div>
      <Wrapper>

        <LoginForm>
          <LogoBox>
            <img alt='logo' src='/images/logo.png' />
          </LogoBox>

          <ThumbnailBox>
            <img alt='thumbnail' src='/images/thumbnail.png' />
          </ThumbnailBox>

          <LoginButtonGroup>
            <LoginButton onClick={() => onAuth('kakao')}>
              <img alt='kakao_logo' src='/images/kakao.png' />
            </LoginButton>
            <LoginButton onClick={() => onAuth('naver')}>
              <img alt='naver_logo' src='/images/naver.png' />
            </LoginButton>
            <LoginButton onClick={() => onAuth('google')}>
              <img alt='google_logo' src='/images/google.png' />
            </LoginButton>
            <LoginButton onClick={() => onAuth('apple')}>
              <img alt='apple_logo' src='/images/apple.png' />
            </LoginButton>
          </LoginButtonGroup>

          <Description>DC Wow 어드민 페이지</Description>

        </LoginForm>

      </Wrapper>

    </div>
  );
}

const Wrapper = styled.div`
display: flex;
-webkit-box-pack: center;
justify-content: center;
padding-top: 50px;
`

const LoginForm = styled.div`
width: 80%;
max-width: 360px;
min-height: 300px;
padding: 20px 20px 30px;
border-radius: 20px;
background-color: rgb(255, 255, 255);
text-align: center;
display: flex;
flex-direction: column;
justify-content: space-between;
`

const LogoBox = styled.div`

img {
  width: 70%;
}
`

const ThumbnailBox = styled.div`
img {
  width: 95%;
}
`

const Description = styled.div`
text-align: right;
color: rgb(72, 72, 72);
font-weight: 300;
`

const LoginButtonGroup = styled.div`
display: flex;
-webkit-box-pack: center;
justify-content: center;
`

const LoginButton = styled.div`
cursor: pointer;
padding: 6px;

img {
  width: 56px;
}
`