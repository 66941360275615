import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { Modal } from '../shared/Modal';
import { ModalContent } from '../shared/ModalContent';
import { HOST_URL } from '../shared/env'
import { convertDatetime } from '../shared/date';

export function BoardFaq() {

  const navigate = useNavigate()

  const [item, setItem] = useState({
    subject: '',
    content: ''
  })
  const [opendNewFaq, setOpendNewFaq] = useState(false)
  const [opendEditFaq, setOpendEditFaq] = useState(false)

  const addFaq = async (notice) => {

    await axios.post(`${HOST_URL}/app/appFaqRegist`, {
      lang: 'ko',
      token: localStorage.getItem('admin_token'),
      faq_type : "faq",
      subject: notice.subject,
      content: notice.content
    })

    setOpendNewFaq(false)
    navigate(0)

  }

  const editFaq = async (notice) => {

    await axios.post(`${HOST_URL}/app/appAdminFaqUpdate`, {
      lang: 'ko',
      faq_seq: item.faq_seq,
      faq_type: 'faq',
      token: localStorage.getItem('admin_token'),
      subject: notice.subject,
      content: notice.content
    })

    setOpendEditFaq(false)
    navigate(0)
    
  }  

  return (
    <ContentLayout 
      title='자주묻는질문 관리'
      description='자주묻는질문 관리 페이지입니다'
      buttons={[
        { label: '등록', onClick: () => setOpendNewFaq(true)}
      ]}
    >

      <Table
        apiUrl={`${HOST_URL}/app/appAdminFaqList`}
        listName='faqs'
        countName='totalCount'
        columns={[
          { name: '자주묻는질문 번호', selector: row => row.faq_seq },
          { name: '제목', selector: row => row.subject },
          { name: '내용', selector: row => row.content },
          { name: '등록자', selector: row => row.crt_usr.user_nm },
          { name: '등록자 이메일', selector: row => row.crt_usr_id },
          { name: '등록일', selector: row => convertDatetime(row.crt_dt) },
        ]}
        searchs={[
          { label: '이메일', value: 'email_addr' },
          { label: '제목', value: 'subject' },
          { label: '내용', value: 'content' }
        ]}
        onClickRow={(row) => {
          setItem(row)
          setOpendEditFaq(true)
        }}
        selectableRows={false}
      />
      <Modal
        isOpen={opendNewFaq}
        onClose={() => setOpendNewFaq(false)}
      >
        <ModalContent 
          title='자주묻는질문 등록' 
          description='자주묻는질문 등록 페이지입니다'
          data={item}
          columns={ [
            { label: '제목', name: 'subject', type: 'text', editable: true },
            { label: '내용', name: 'content', type: 'textarea', editable: true }
          ]}
          onSubmit={addFaq}
          onClose={() => setOpendNewFaq(false)}
          submitLabel='등록'
        />        
      </Modal>

      <Modal
        isOpen={opendEditFaq}
        onClose={() => {
          setOpendEditFaq(false)
          setItem({}) 
        }}
      >
        <ModalContent 
          title='자주묻는질문 수정' 
          description='자주묻는질문 수정 페이지입니다'
          data={item}
          columns={ [
            { label: '제목', name: 'subject', type: 'text', editable: true },
            { label: '내용', name: 'content', type: 'textarea', editable: true }
          ]}
          onSubmit={editFaq}
          onClose={() => {
            setOpendEditFaq(false)
            setItem({}) 
          }}
          submitLabel='수정'
        />        
      </Modal>

    </ContentLayout>
  );
}
