import styled from '@emotion/styled'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { Header, HeaderInfo } from '../shared/styled'
import { useEffect, useState } from 'react';
import { HOST_URL } from '../shared/env'
import axios from 'axios';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export function Home() {

  const [enbed, setEnbed] = useState(false)

  const [data, setData] = useState({
    ads: [],
    users: [],
    coins: [],
    points: [],
    budgets: []
  })

  useEffect(() => {

    (async () => {

      const token = localStorage.getItem('admin_token')

      if (token) {

        try {
         
          const resp = await axios.post(`${HOST_URL}/app/appAdminHomeTotalList`, {
            lang: 'ko',
            token
          })

          setData(resp.data.result)
          
        } catch (error) {
          localStorage.setItem('admin_token', '')
        }

      }

    })()

  }, [])

  return (<Container>

    <ChartGroup>
      <ChartLargeBox>
        <ChartPadding>
          <Header>
            <HeaderInfo>
              <h2>광고관리 집계</h2>
              <h5>광고관리 집계 차트입니다</h5>
            </HeaderInfo>
          </Header>
          <Bar options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top'
              }
            },
          }} 
          data={{
            labels: data.ads ? data.ads.map(e => e.ad_subj) : [], 
            datasets: [
              {
                label: '광고노출수',
                data: data.ads ? data.ads.map(e => e.ad_view_cnt) : [], 
                backgroundColor: 'rgba(255, 206, 62, 0.5)',
              },
              {
                label: '광고클릭수',
                data: data.ads ? data.ads.map(e => e.ad_click_cnt) : [],
                backgroundColor: 'rgba(0, 100, 255, 0.5)',
              }        
            ],
          }} 
        />
        </ChartPadding>
      </ChartLargeBox>
    </ChartGroup>
    
    <ChartGroup>

      <ChartBox>
        <ChartPadding>
          <Header>
            <HeaderInfo>
              <h2>신규/탈퇴회원 집계</h2>
              <h5>신규/탈퇴회원 집계 차트입니다</h5>              
            </HeaderInfo>
          </Header>
          <Line 
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top'
              }
            },
          }} 
          data={{
            labels: data.users.totals ? data.users.totals.map(e => e.date.split('-').slice(1).join('-')).reverse() : [], 
            datasets: [
              {
                label: '신규회원',
                data: data.users.totals ? data.users.totals.map(e => e.signup).reverse() : [], 
                borderColor: 'rgb(255, 206, 62)',
                backgroundColor: 'rgba(255, 206, 62, 0.5)',
              },
              {
                label: '탈퇴회원',
                data: data.users.totals ? data.users.totals.map(e => e.withdrawal).reverse() : [], 
                borderColor: 'rgb(0, 100, 255)',
                backgroundColor: 'rgba(0, 100, 255, 0.5)',
              },
            ],
          }} />
        </ChartPadding>
      </ChartBox>

      <ChartBox>
        <ChartPadding>
          <Header>
            <HeaderInfo>
              <h2>코인관리 집계</h2>
              <h5>신규/탈퇴회원 집계 차트입니다</h5>
            </HeaderInfo>
          </Header>
          <Line 
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top'
              }
            },
          }}
          data={{
            labels: data.coins.totals ? data.coins.totals.map(e => e.date.split('-').slice(1).join('-')).reverse() : [], 
            datasets: [
              {
                label: '총 획득',
                data: data.coins.totals ? data.coins.totals.map(e => e.inc_sum).reverse() : [], 
                borderColor: 'rgb(255, 206, 62)',
                backgroundColor: 'rgba(255, 206, 62, 0.5)',
              },
              {
                label: '총 지출',
                data: data.coins.totals ? data.coins.totals.map(e => -e.mns_sum).reverse() : [], 
                borderColor: 'rgb(0, 100, 255)',
                backgroundColor: 'rgba(0, 100, 255, 0.5)',
              },
            ],
          }} />
          </ChartPadding>
      </ChartBox>   

      <ChartBox>
        <ChartPadding>
          <Header>
            <HeaderInfo>
              <h2>포인트관리 집계</h2>
              <h5>신규/탈퇴회원 집계 차트입니다</h5>
            </HeaderInfo>
          </Header>
          <Line 
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top'
              }
            },
          }} 
          data={{
            labels: data.points.totals ? data.points.totals.map(e => e.date.split('-').slice(1).join('-')).reverse() : [], 
            datasets: [
              {
                label: '총 획득',
                data: data.points.totals ? data.points.totals.map(e => e.inc_sum).reverse() : [], 
                borderColor: 'rgb(255, 206, 62)',
                backgroundColor: 'rgba(255, 206, 62, 0.5)',
              },
              {
                label: '총 지출',
                data: data.points.totals ? data.points.totals.map(e => -e.mns_sum).reverse() : [], 
                borderColor: 'rgb(0, 100, 255)',
                backgroundColor: 'rgba(0, 100, 255, 0.5)',
              },
            ],
          }} />
        </ChartPadding>
      </ChartBox>   

      <ChartBox>
        <ChartPadding>
          <Header>
            <HeaderInfo>
              <h2>광고예산관리 집계</h2>
              <h5>신규/탈퇴회원 집계 차트입니다</h5>
            </HeaderInfo>
          </Header>
          <Line 
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top'
              }
            },
          }}
          data={{
            labels: data.budgets.totals ? data.budgets.totals.map(e => e.date.split('-').slice(1).join('-')).reverse() : [], 
            datasets: [
              {
                label: '충전 합산',
                data: data.budgets.totals ? data.budgets.totals.map(e => e.charge_sum).reverse() : [], 
                borderColor: 'rgb(255, 206, 62)',
                backgroundColor: 'rgba(255, 206, 62, 0.5)',
              },
              {
                label: '환불',
                data: data.budgets.totals ? data.budgets.totals.map(e => -e.refund_sum).reverse() : [], 
                borderColor: 'rgb(0, 100, 255)',
                backgroundColor: 'rgba(0, 100, 255, 0.5)',
              },
            ],
          }} />
        </ChartPadding>
      </ChartBox>                            

    </ChartGroup>
  </Container>)
}

const Container = styled.div``

const ChartGroup = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-around;
`

const ChartBox = styled.div`
width: 45%;
margin: 8px 16px;
border-radius: 8px;
background-color: rgb(255, 255, 255);

@media only screen and (max-width: 768px) {
  width: 100%;
}
`

const ChartPadding = styled.div`
padding: 20px;
`

const ChartLargeBox = styled(ChartBox)`
width: 95%;
`