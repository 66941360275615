import { WithContext as ReactTags } from 'react-tag-input';
import styled from '@emotion/styled';
import { ReactSortable } from 'react-sortablejs';

export const Header = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`

export const HeaderInfo = styled.div`
h2, h5 {
  margin: 0;
  padding: 0;
}

h5 {
  color: #4d5156;
  font-weight: 400;
  padding-top: 4px;
}
`

export const StyledDataViewerTable = styled.table`
width: 100%;
margin-top: 8px;
margin-bottom: 50px;

td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
td {
  padding: 8px 0;
}
td:nth-child(1) {
  width: 20%;
}
td:nth-child(2) {
  width: 80%;
}
`

const StyledDataViewerWrapper = styled.div`
width: 100%;
overflow: scroll;
min-height: 85%;
padding-bottom: 25px;
`

export function DataViewerTable({ children }) {

  return <StyledDataViewerWrapper>
    <StyledDataViewerTable>
      {children}
    </StyledDataViewerTable>
  </StyledDataViewerWrapper>
}

export const FloatingBottomButton = styled.input`
background-color: #FFCE3E;
text-align: center;
width: 100%;
height: 40px;
display: flex;
justify-content: center;
align-items: center;
position: fixed;
bottom: 0;
left: 0;
cursor: pointer;
border: none;
`


export const FormLabel = styled.div`
font-weight: 600;
margin: 0;
padding: 8px 0;
`

export const FormRadioLabels = styled.div`
padding: 10px 0;

label {
  cursor: pointer;
  padding: 10px;
  padding-left: 0;
}
`

export const FormSliderBox = styled.div`
padding: 20px 10px;
padding-top: 5px;
`

export const FormInput = styled.input`
box-sizing: border-box;
width: 100%;
padding: 15px;
border-radius: 5px;
background-color: rgb(235, 235, 235);
border: 0px;
outline: none;
font-size: 14px;
font-weight: 500;
text-align: left;
border: 2px solid transparent;

:focus {
  border: 2px solid rgb(0, 100, 255);
}
`

export const FormSmallInput = styled(FormInput)`
width: fit-content;
`

export const FormSelect = styled.select`
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
box-sizing: border-box;
width: fit-content;
padding: 15px;
border-radius: 5px;
background-color: rgb(235, 235, 235);
border: 0px;
outline: none;
font-size: 14px;
font-weight: 500;
text-align: left;
margin-right: 8px;
border: 2px solid transparent;

:focus {
  border: 2px solid rgb(0, 100, 255);
}
`

export const FormTextarea = styled.textarea`
box-sizing: border-box;
width: 100%;
height: 150px;
padding: 15px;
border-radius: 5px;
background-color: rgb(235, 235, 235);
border: 0px;
outline: none;
font-size: 14px;
font-weight: 500;
text-align: left;
border: 2px solid transparent;

:focus {
  border: 2px solid rgb(0, 100, 255);
}
`

export const FormImg = styled.div`
width: 150px;
height: 150px;
border-radius: 12.5px;
background-position: center center;
background-size: cover;
margin-top: 12px;
margin-right: 12px;
text-align: right;
`

export const FormDeleteImgIcon = styled.img`
background-color: #FFCE3E;
width: 12px;
border-radius: 4px;
padding: 2px 4px;
margin-top: 4px;
margin-right: 4px;
cursor: pointer;
`

export const FormImgs = styled.div`
display: flex;
flex-wrap: wrap;
margin-bottom: 12px;

div {
  cursor: grab;
}
`

export const FormSortableImgs = styled(ReactSortable)`
display: flex;
flex-wrap: wrap;
margin-bottom: 12px;
`

export const FormSubmitButton = styled.input`
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
background-color: #FFCE3E;
cursor: pointer;
border: none;
width: 100%;
height: 40px;
border: .5px solid rgba(0,0,0,0.1);
margin-top: 28px;
`

export const FormButton = styled.div`
cursor: pointer;
padding: 8px 14px;
border-radius: 8px;
background-color: rgb(235, 235, 235);
border: .5px solid rgba(0,0,0,0.1);
width: fit-content;
margin-top: 12px;
`

export const FormKeywords = styled(ReactTags)``

export const ButtonGroup = styled.div`
display: flex;
justify-content: flex-end;
margin: 8px 0;

label {
  cursor: pointer;
  padding: 6px;
}

div {
  border-radius: 5px;
  background-color: rgb(235, 235, 235);
  padding: 6px;
  cursor: pointer;
  margin-left: 8px;
}
`

export const FormFileUploadBox = styled.label`
display: inline-block;
border: 1px dashed #9C9C9C;
border-radius: 12.5px;
width: 150px;
height: 150px;
margin-top: 12px;
background-image: url("/images/camera-solid.png");
background-position: center center;
background-size: 40px;
background-repeat: no-repeat;
cursor: pointer;
`

export const FormPendingAlert = styled.div`
border-radius: 16px;
padding: 14px;
font-size: 14px;
font-weight: 500;
`

export const FormSuccessAlert = styled.div`
color: #fff;
background-color: rgb(0, 100, 255);
border-radius: 16px;
padding: 14px;
font-size: 14px;
font-weight: 600;
`

export const FormLabelNotice = styled.span`
font-size: 11px;
color: #ee6b6e;
`

export const FormLabelError = styled.span`
font-size: 12px;
color: #ee6b6e;
`