import React, { useEffect, useState } from 'react';
import { ContentLayout } from '../shared/content-layout';
import { FormSubmitButton, FormLabel, FormInput, FormSmallInput, FormSelect, FormImg, FormImgs, FormTextarea, FormButton, FormKeywords, ButtonGroup, FormRadioLabels, FormFileUploadBox, FormPendingAlert, FormSuccessAlert, FormLabelNotice, FormSortableImgs, FormDeleteImgIcon, FormLabelError } from '../shared/styled'
import { useFieldArray, useForm } from 'react-hook-form'
import readXlsxFile from 'read-excel-file'
import { HOST_URL } from '../shared/env';
import { FormDropDownBox } from '../shared/FormDropDownBox';
import axios from 'axios'

export function AdNewOnline() {

  const { control, register, handleSubmit, setValue, watch, formState: { errors } } = useForm()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ads',
  })

  const [thumbnails, setThumbnails] = useState([])
  const [images, setImages] = useState([])
  const [keywords, setKeywords] = useState([])  
  const [shopNames, setShopNames] = useState([])

  const [prod_codes, setProdCodes] = useState([])

  const [submited, setSubmited] = useState(false)

  const [ads, setAds] = useState([])
  const watchAds = watch('ads')

  const onSubmit = async (data) => {

    if (submited) return

    for (let i = 0; i < fields.length; i++) setValue(`ads.${i}.opend`, false)    

    setSubmited(true)
  
    for (let i = 0; i < data.ads.length; i++) {

      const e = data.ads[i]


      const defaultPayload = {
        perd_type: null,
        budget_type: null,
        area_type: null
      }

      const payload = {
        ...defaultPayload,
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        ad_seq: 0,
        ad_type: 'D0003',
        ad_subj: e.ad_subj,
        ad_cont: e.ad_cont,
        prod_nm: e.prod_nm,
        shop_nm: e.shop_nm,
        shop_cd: prod_codes.find(x => x.dtl_cd_nm === shopNames.find(y => y.no === e.no).value)?.dtl_cd_id,
        list_prc: e.list_prc,
        discnt_prc: e.discnt_prc,
        prod_link: e.prod_link,
        ad_keyword: keywords.find(x => x.no === e.no).tags.map(e => e.text).join(','),
        discnt_cd: e.discnt_cd,
        exp_perd: e.exp_perd ? 60 : 30
      }      

      if (e.done) continue;

      const vaildKeys = [
        'ad_subj',
        'ad_cont',
        'prod_nm',
        'shop_nm',
        'shop_cd',
        'list_prc',
        'discnt_prc',
        'prod_link'
      ]

      const unvaild = vaildKeys.filter(x => payload[`${x}`] === '')

      if (!thumbnails.find(x => x.no === e.no).value) unvaild.push('ad_img_rep')
      if (!keywords.find(x => x.no === e.no).tags.length) unvaild.push('ad_keyword')

      setValue(`ads.${i}.failed`, !!unvaild.length)

      if (unvaild.length) {
        setValue(`ads.${i}.failed_text`, `[${unvaild.join(', ')}]가 누락되어 있습니다`)
        continue;
      }

      const thumbnail = thumbnails.find(x => x.no === e.no)
      const thumbnailFormData = new FormData()
      thumbnailFormData.append('files', thumbnail.file)

      const thumbnailResp = await axios({
        method: 'post',
          url: `${HOST_URL}/app/upload`,
        data: thumbnailFormData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      const ad_img_rep = thumbnailResp.data.result[0]
      const ad_imgs = {}

      const _images = images.find(x => x.no === e.no)

      for (let x = 0; x < _images.values.length; x++) {
        const image = _images.values[x]

        const formData = new FormData()
        formData.append('files', image.file)
  
        const imageResp = await axios({
          method: 'post',
          url: `${HOST_URL}/app/upload`,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        ad_imgs[`ad_img${x + 1}`] = imageResp.data.result[0]
        
      }

      await axios.post(`${HOST_URL}/app/appBizAdRegist`, {
        ...payload,
        ...ad_imgs,
        ad_img_rep,
      })

      setValue(`ads.${i}.done`, true)
 
    }

    setSubmited(false)    

  }

  useEffect(() => {

    if (watchAds) setAds(watchAds)

  }, [watchAds])  

  useEffect(() => {

    (async () => {

      const respProdCodes = await axios.post(`${HOST_URL}/app/appCodeList`, {
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        mst_cd_id: 'M0005'
      })

      setProdCodes(respProdCodes.data.result)

    })()

  }, [])  

  return (
    <ContentLayout 
      title='온라인 할인 행사 등록' 
      description='온라인 할인 행사 등록 페이지입니다'
    >

      <ButtonGroup>
        <div>
          <label htmlFor='upload-excel'>엑셀업로드</label>
          <input type='file' id='upload-excel' style={{display: 'none'}} onChange={(e) => {

            readXlsxFile(e.target.files[0]).then( async (rows) => {

              const keys = rows[0].map((e, i) => ({
                index: i,
                name: e.split('(')[1].replace(')', '')
              }))

              const _rows = rows.slice(1)

              for (let i = 0; i < _rows.length; i++) {

                const e = _rows[i];

                const data = e.reduce((acc, cur, i) => {
                  
                  if (cur) acc[[keys.find(e => e.index === i).name]] = cur

                  return acc
                }, { })

                let file = undefined,
                    blob = undefined

                if (data.ad_img_rep) {

                  const resp = await axios.get(data.ad_img_rep, { responseType: 'blob' })

                  file = new File([resp.data], 'exampleimage')
                  blob = URL.createObjectURL(file)

                }

                const adImages = []

                const ad_imgs = ['ad_img1', 'ad_img2', 'ad_img3', 'ad_img4', 'ad_img5', 'ad_img6', 'ad_img7', 'ad_img8', 'ad_img9', 'ad_img10']

                for (let x = 0; x < ad_imgs.length; x++) {

                  const img = data[`${ad_imgs[x]}`]

                  if (img) {

                    const resp = await axios.get(img, { responseType: 'blob' })
                    let file = new File([resp.data], 'exampleimage')
                    let blob = URL.createObjectURL(file)

                    adImages.push({
                      value : blob,
                      file
                    })

                  }

                }

                const no = Math.random().toString().slice(5)

                append({
                  no,
                  opend: false,
                  ...data
                })
                setThumbnails(e => [...e, { no, value: blob, file }])
                setImages(e => [...e, { no, values: [...adImages] }])
                setKeywords(e => [...e, { no, tags: [] }])
                setShopNames(e => [...e, { no, value: '' }])

              }

            })

            e.target.value = ''

          }} />
        </div>
        <div onClick={() => {
          const no = new Date().getTime()
          append({
            no,
            opend: false            
          })
          setThumbnails(e => [...e, { no, value: '' }])
          setImages(e => [...e, { no, values: [] }])
          setKeywords(e => [...e, { no, tags: [] }])
          setShopNames(e => [...e, { no, value: '' }])          
        }}>등록 폼 추가</div>
      </ButtonGroup>

      <form onSubmit={handleSubmit(onSubmit)}>

      {fields.map((field, i) => (
        <FormDropDownBox opend={watch(`ads.${i}.opend`)} doned={watch(`ads.${i}.done`)} key={i} text={watch(`ads.${i}.failed`) ? <FormLabelNotice>{watch(`ads.${i}.failed_text`)}</FormLabelNotice> : (watch(`ads.${i}.ad_subj`) || '제목을 입력해주세요')} onDelete={() => remove(i)} onOpend={() => setValue(`ads.${i}.opend`, !watch(`ads.${i}.opend`))}>
          <div>
            <FormLabel>제목 <small>(ad_subj) <FormLabelNotice>*필수</FormLabelNotice> {(errors.ads && errors.ads[i] && errors.ads[i].ad_subj) && <FormLabelError>제목은 100자 이하로 적어주세요</FormLabelError>}</small>
            </FormLabel>
            <div>
              <FormInput placeholder='5자 이상 50자 이내로 입력해주세요' { ...register(`ads.${i}.ad_subj`, { maxLength: 100 }) } type="text" />
            </div>
          </div>
          <div>
            <FormLabel>내용 <small>(ad_cont) <FormLabelNotice>*필수</FormLabelNotice> {(errors.ads && errors.ads[i] && errors.ads[i].ad_cont) && <FormLabelError>내용은 2000자 이하로 적어주세요</FormLabelError>}</small>
            </FormLabel>
            <div>
              <FormTextarea placeholder='내용을 5자 이상 2000자 이내로 입력해주세요' { ...register(`ads.${i}.ad_cont`, { maxLength: 2000 }) } type="text"></FormTextarea>
            </div>
          </div>
          <div>
            <FormLabel>대표사진 <small>(ad_img_rep) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <FormFileUploadBox
              htmlFor={`upload-thumbnail_${i}`}
              onDragOver={(e) => { 
                e.preventDefault()
                e.stopPropagation()
              }}
              onDrop={(e) => {
                if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                  setThumbnails(x => x.map(y => {

                    if (y.no === field.no) {
                      y.value = URL.createObjectURL(e.dataTransfer.files[0])
                      y.file = e.dataTransfer.files[0]
                    }

                    return y
                  }))
                }
                e.preventDefault()
              }}
            >
            <div>
              <input id={`upload-thumbnail_${i}`} type="file" style={{display: 'none'}} onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setThumbnails(x => x.map(y => {

                    if (y.no === field.no) {
                      y.value = URL.createObjectURL(e.target.files[0])
                      y.file = e.target.files[0]
                    }

                    return y
                  }))
                }
              }} />
            </div>
              {thumbnails.find(e => e.no == field.no).value && <FormImg style={{ marginTop: 0, backgroundImage: `url(${thumbnails.find(e => e.no == field.no).value})`}} /> }
            </FormFileUploadBox>
          </div>
          <div>
            <FormLabel>상세사진 <small>(ad_img)</small>
            </FormLabel>
            <div>
                <FormImgs>
                  <FormSortableImgs list={images.find(e => e.no == field.no).values} setList={(newState) => { 
                    setImages(images.map(y => {
                        y.values = newState
                        return y
                      }))
                    }}>
                    {images.find(e => e.no == field.no).values.map((item, i) => (
                      <FormImg key={item.value} style={{ backgroundImage: `url(${item.value})`}}>
                      <FormDeleteImgIcon alt='xmark' src='/images/xmark-solid.svg' onClick={() => {
                        setImages(images.map((y) => {
                            if (y.no === field.no) {

                              y.values = y.values.filter((_, idx) => idx !== i)

                            }
                          return y
                        }))
                      }} />
                    </FormImg>
                    ))}
                  </FormSortableImgs>                  
                  <FormFileUploadBox
                    htmlFor={`upload-images_${i}`}
                    onDragOver={(e) => { 
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onDrop={(e) => {
                      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                        setImages(images.map(y => {
                          if (y.no === field.no) y.values = [...y.values, ...Array.from(e.dataTransfer.files).map(e => ({
                            value: URL.createObjectURL(e),
                            file: e
                          }))]

                          return y
                        }))
                      }
                      e.target.value = ''
                      e.preventDefault()
                    }}
                  >
                    <div>
                      <input id={`upload-images_${i}`} type="file" style={{display: 'none'}} multiple onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          setImages(images.map(y => {
                            if (y.no === field.no) y.values = [...y.values, ...Array.from(e.target.files).map(e => ({
                              value: URL.createObjectURL(e),
                              file: e
                            }))]

                            return y
                          }))
                        }
                        e.target.value = ''
                      }} />
                    </div>
                  </FormFileUploadBox>
                </FormImgs>
            </div>
          </div>
          <div>
            <FormLabel>제품명 <small>(prod_nm) <FormLabelNotice>*필수</FormLabelNotice> {(errors.ads && errors.ads[i] && errors.ads[i].prod_nm) && <FormLabelError>제품명은 100자 이하로 적어주세요</FormLabelError>}</small>
            </FormLabel>
            <div>
              <FormInput placeholder='제품명을 입력해주세요' { ...register(`ads.${i}.prod_nm`, { maxLength: 100 }) } type="text" />
            </div>
          </div>
          <div>
            <FormLabel>쇼핑몰 <small>(shop_nm) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <div>
              <FormSelect onChange={(e) => {
                setShopNames(names => names.map(x => {
                  if (x.no === field.no) {
                    x.value = e.target.value
                    setValue(`ads.${i}.shop_nm`, e.target.value !== '직접입력' ? e.target.value : '')
                  }
                  return x
                }))
              }}>
                <option value=''>===============</option>
                {prod_codes.map((e, i) => <option key={i} value={e.dtl_cd_nm}>{e.dtl_cd_nm}</option>)}
              </FormSelect>
              <FormSmallInput type={shopNames.find(e => e.no === field.no).value === '직접입력' ? 'text' : 'hidden'} { ...register(`ads.${i}.shop_nm`) } />
            </div>
          </div>
          <div>
            {/* <FormLabel>가게명/쇼핑몰 코드 <small>(shop_cd) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel> */}
            <div>
              <FormSmallInput type='hidden' { ...register(`ads.${i}.shop_cd`) } value={prod_codes.find(e => e.dtl_cd_nm === shopNames.find(e => e.no === field.no).value)?.dtl_cd_id} />
            </div>
          </div>          
          <div>
            <FormLabel>정가 <small>(list_prc) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <div>
              <FormSmallInput placeholder='숫자만 입력하세요' { ...register(`ads.${i}.list_prc`) } type="number" />
            </div>
          </div>
          <div>
            <FormLabel>할인가격 <small>(discnt_prc) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <div>
              <FormSmallInput placeholder='숫자만 입력하세요' { ...register(`ads.${i}.discnt_prc`) } type="number" />
            </div>
          </div>
          <div>
            <FormLabel>링크 <small>(prod_link) <FormLabelNotice>*필수</FormLabelNotice></small> {(errors.ads && errors.ads[i] && errors.ads[i].prod_link) && <FormLabelError>링크는 500자 이하로 적어주세요</FormLabelError>}
            </FormLabel>
            <div>
              <FormInput placeholder='url을 붙여넣어 주세요' { ...register(`ads.${i}.prod_link`, { maxLength: 500 }) } type="text" />
            </div>
          </div>
          <div>
            <FormLabel>키워드 <small>(ad_keyword) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <div>
              <FormKeywords
                classNames={{
                  tagInputField: 'form-keyword-input',
                  selected: 'form-keyword-selected-box',
                  tag: 'form-keyword',
                  remove: 'form-keyword-remove-icon'
                }}
                tags={keywords.find(e => e.no == field.no).tags}
                delimiters={[188, 13]}
                handleDelete={(idx) => {
                  setKeywords(keywords => {

                    return keywords.map(e => {

                      if (e.no === field.no) {
                        const k = e.tags.filter((tag, index) => index !== idx)
                        e.tags = k
                      }

                      return e
                    })
                  })
                }}
                handleAddition={(tag) => {
                  setKeywords(keywords => {
                    return keywords.map(e => {

                      if (e.no === field.no) {
                        const k = [...e.tags, tag]
                        e.tags = k
                      }

                      return e
                    })
                  })
                }}
                inputFieldPosition='top'
                autocomplete={true}
                autofocus={false}
                placeholder='Enter를 누르시면 키워드가 추가됩니다'
              />
            </div>
          </div>
          <div>
            <FormLabel>할인코드 <small>(discnt_cd)</small> {(errors.ads && errors.ads[i] && errors.ads[i].discnt_cd) && <FormLabelError>할인코드는 45자 이하로 적어주세요</FormLabelError>}
            </FormLabel>
            <div>
              <FormSmallInput placeholder='할인코드를 입력하세요' { ...register(`ads.${i}.discnt_cd`, { maxLength: 45 }) } type="text" />
            </div>
          </div>
          <div>
            <FormLabel>유효기간 <small>(exp_perd)</small>
            </FormLabel>
            <div>
              <FormRadioLabels>
                <label>
                  <FormSmallInput { ...register(`ads.${i}.exp_perd`) } defaultChecked={true} type="checkbox" />
                  정보를 60일간 유지해주세요
                </label>
              </FormRadioLabels>              
            </div>
          </div>
        </FormDropDownBox>
        ))}

        <div style={{height: '25px'}}>
          {ads.filter(e => e.failed).length === 0 ?
            <div>
              {ads.filter(e => !e.done).length ?
              <FormPendingAlert>
                🚀 현재 {ads.length}개의 광고를 작성중입니다... ({ads.filter(e => e.done).length}/{ads.length})
              </FormPendingAlert>
              :
              !!ads.length && <FormSuccessAlert>🎉 모든 광고가 성공적으로 등록되었습니다.</FormSuccessAlert>
              }
            </div>
          :
          <FormSuccessAlert>
            🎉 {ads.length}개의 광고중 {ads.filter(e => e.done).length}개가 성공적으로 등록되었습니다. ({ads.filter(e => e.done).length}/{ads.length})
          </FormSuccessAlert>
          }
        </div>

        <FormSubmitButton type='submit' value='등록' />

      </form>

      <ButtonGroup>
        <FormButton type="submit" onClick={() => window.open('/download/new-online-ad.xlsx')}>온라인 할인 엑셀 양식 받기</FormButton>
      </ButtonGroup>

    </ContentLayout>
  );
}
