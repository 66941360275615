import React, { useState } from 'react';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { Modal } from '../shared/Modal';
import { ModalContent } from '../shared/ModalContent';
import { HOST_URL } from '../shared/env'
import { convertDatetime } from '../shared/date';

export function CommentMgt() {

  const [item, setItem] = useState(undefined)
  const [opend, setOpend] = useState(false)

  return (
    <ContentLayout 
      title='한줄평 관리'
      description='한줄평 관리 페이지입니다'
      buttons={[
      ]}
    >

      <Table
        apiUrl={`${HOST_URL}/app/appAdminAdCommentList`}
        listName='comments'
        countName='totalCount'
        columns={[
          { name: '한줄평 번호', selector: row => row.cmt_seq },
          { name: '광고 번호', selector: row => row.ad_seq },
          { name: '한줄평', selector: row => row.cmt_str },
          { name: '등록자', selector: row => row.crt_usr.user_nm },
          { name: '등록자 이메일', selector: row => row.crt_usr_id },
          { name: '사용여부', selector: row => row.use_yn },
          { name: '등록일', selector: row => convertDatetime(row.crt_dt) },
        ]}
        searchs={[
          { label: '이메일', value: 'email_addr' },
          { label: '한줄평', value: 'cmt_str' }
        ]}
        onClickRow={(row) => {
          setItem(row)
          setOpend(true)
        }}
        selectableRows={false}
      />
      <Modal
        isOpen={opend}
        onClose={() => setOpend(false)}
      >
        <ModalContent 
          title='한줄평 관리' 
          description='한줄평 관리 페이지입니다'
          data={item}
          columns={ [
            { label: '한줄평 번호', name: 'cmt_seq', type: 'text'},
            { label: '광고 번호', name: 'ad_seq', type: 'text'},
            { label: '한줄평', name: 'cmt_str', type: 'text' },
            { label: '등록자', name: 'crt_usr.user_nm', type: 'text' },
            { label: '등록자이메일', name: 'crt_usr_id', type: 'text'},
            { label: '사용여부', name: 'use_yn', type: 'text'},
            { label: '등록일', name: 'crt_dt', type: 'date'}
          ]}
          onClose={() => setOpend(false)}
        />        
      </Modal>
    </ContentLayout>
  );
}
