import React, { useEffect, useState } from 'react';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { Modal } from '../shared/Modal';
import { ModalContent } from '../shared/ModalContent';
import { HOST_URL } from '../shared/env'
import axios from 'axios';
import { convertDatetime } from '../shared/date';
import { convertNumberComma } from '../shared/comma';

export function PointMgt() {

  const [item, setItem] = useState(undefined)
  const [opend, setOpend] = useState(false)

  const [point_codes, setPointCodes] = useState([])

  const getCodeName = (codes, data) => {
    if (data && codes.length) {
      const prod = codes.find(e => e.dtl_cd_id === data)
      return prod ? prod.dtl_cd_nm : data
    }
  }  

  useEffect(() => {

    (async () => {

      const respPointCodes = await axios.post(`${HOST_URL}/app/appCodeList`, {
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        mst_cd_id: 'M0009'
      })

      setPointCodes(respPointCodes.data.result)

    })()
  
  }, [])    

  return (
    <ContentLayout 
      title='포인트 관리'
      description='포인트 관리 페이지입니다'
      buttons={[
      ]}
    >

      <Table
        apiUrl={`${HOST_URL}/app/appAdminPointList`}
        listName='points'
        countName='totalCount'
        columns={[
          { name: '포인트 이력번호', selector: row => row.point_hist_seq },
          { name: '이름', selector: row => row.email_addr2.user_nm },
          { name: '이메일', selector: row => row.email_addr },
          { name: '포인트 금액', selector: row => convertNumberComma(row.point_amt) },
          { name: '포인트적립유형', selector: row => getCodeName(point_codes, row.point_get_type) },
          { name: '등록일', selector: row => convertDatetime(row.crt_dt) }
        ]}
        searchs={[
          { label: '이메일', value: 'email_addr' },
          { label: '포인트적립유형', value: 'point_get_type', options: point_codes.map(e => ({ label: e.dtl_cd_nm, value: e.dtl_cd_id })) }
        ]}
        onClickRow={(row) => {
          setItem(row)
          setOpend(true)
        }}
        selectableRows={false}
      />
      <Modal
        isOpen={opend}
        onClose={() => setOpend(false)}
      >
        <ModalContent 
          title='포인트 관리' 
          description='포인트 관리 페이지입니다'
          data={item}
          columns={ [
            { label: '포인트 이력번호', name: 'point_hist_seq', type: 'text'},
            { label: '이름', name: 'email_addr2.user_nm', type: 'text'},
            { label: '이메일', name: 'email_addr', type: 'text' },
            { label: '포인트 금액', name: 'point_amt', type: 'number' },
            { label: '포인트적립유형', name: 'point_get_type', type: 'text', selector: row => getCodeName(point_codes, row.point_get_type) },
            { label: '등록일', name: 'crt_dt', type: 'date'},
          ]}
          onClose={() => setOpend(false)}
        />        
      </Modal>
    </ContentLayout>
  );
}
