import React, { useEffect, useState } from 'react';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { Modal } from '../shared/Modal';
import { ModalContent } from '../shared/ModalContent';
import { HOST_URL } from '../shared/env'
import axios from 'axios';
import { convertDatetime } from '../shared/date';


export function AdReport() {

  const [item, setItem] = useState(undefined)
  const [opend, setOpend] = useState(false)

  const [dislike_codes, setDislikeCodes] = useState([])  

  const getDislikeName = (row) => {
    if (row.dl_str && dislike_codes.length) {
      const dislike = dislike_codes.find(e => e.dtl_cd_id === row.dl_str)
      return dislike ? dislike.dtl_cd_nm : row.dl_str
    }
  }    

  useEffect(() => {

    (async () => {

      const respProdCodes = await axios.post(`${HOST_URL}/app/appCodeList`, {
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        mst_cd_id: 'M0011'
      })
  
      setDislikeCodes(respProdCodes.data.result)


    })()

  }, [])  

  return (
    <ContentLayout 
      title='신고관리' 
      description='광고 신고 관리 페이지입니다'
      buttons={[
      ]}
    >

      <Table
        apiUrl={`${HOST_URL}/app/appAdminAdDislikeList`}
        listName='dislikes'
        countName='totalCount'
        columns={[
          { name: '광고번호', selector: row => row.ad_seq },
          { name: '신고자', selector: row => row.email_addr2.user_nm},
          { name: '신고 내용', selector: row => getDislikeName(row) },
          { name: '이메일', selector: row => row.email_addr },
          { name: '신고일자', selector: row => convertDatetime(row.crt_dt) }
        ]}
        searchs={[
          { label: '이메일', value: 'email_addr' }
        ]}
        onClickRow={(row) => {
          setItem(row)
          setOpend(true)
        }}
        selectableRows={false}
      />
      <Modal
        isOpen={opend}
        onClose={() => setOpend(false)}
      >
        <ModalContent 
          title='신고관리' 
          description='광고 신고 관리 페이지입니다'
          data={item}
          columns={ [
            { label: '광고번호', name: 'ad_seq', type: 'text'},
            { label: '신고자', name: 'email_addr2.user_nm', type: 'text'},
            { label: '신고 내용', name: 'dl_str', type: 'text', selector: row => getDislikeName(row) },
            { label: '신고자 이메일', name: 'email_addr', type: 'text' },
            { label: '신고일자', name: 'crt_dt', type: 'date'}
          ]}
          onClose={() => setOpend(false)}
        /> 
      </Modal>
    </ContentLayout>
  );
}
