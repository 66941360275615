import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { Modal } from '../shared/Modal';
import { HOST_URL } from '../shared/env';
import { ModalContent } from '../shared/ModalContent';
import { convertDatetime } from '../shared/date';
import { convertNumberComma } from '../shared/comma';

export function LottoPayment() {

  const navigate = useNavigate()

  const [item, setItem] = useState(undefined)
  const [opendPayment, setOpendPayment] = useState(false)
  const [opendDetail, setOpendDetail] = useState(false)

  const updateLottoNumber = async (data) => {

    await axios.post(`${HOST_URL}/app/appLottoPayment`, {
      lang: 'ko',
      token: localStorage.getItem('admin_token'),
      lotto_seq: data.lotto_seq,
      lotto_num1: data.lotto_num1,
      lotto_num2: data.lotto_num2,
      lotto_num3: data.lotto_num3,
      lotto_num4: data.lotto_num4
    })

    setOpendPayment(false)
    navigate(0)

  }

  return (
    <ContentLayout 
      title='로또 결과반영' 
      description='로또 결과반영 관리 페이지입니다'
      buttons={[
        { label: '결과 반영', onClick: () => setOpendPayment(true) }
      ]}      
    >

      <Table 
        apiUrl={`${HOST_URL}/app/appAdminUserLottoList`}
        listName='userLottos'
        countName='totalCount'
        columns={[
          { name: '로또회차', selector: row => row.lotto_seq },
          { name: '구매자', selector: row => row.email_addr2.user_nm },
          { name: '구매자 이메일', selector: row => row.email_addr },
          { name: '당첨금', selector: row => convertNumberComma(row.win_amt) },
          { name: '첫번째 번호', selector: row => row.lotto_num1 },
          { name: '두번째 번호', selector: row => row.lotto_num2 },
          { name: '세번째 번호', selector: row => row.lotto_num3 },
          { name: '네번째 번호', selector: row => row.lotto_num4 },
          { name: '구매일시', selector: row => convertDatetime(row.crt_dt) },
        ]}
        searchs={[
          { label: '로또회차', value: 'lotto_seq' }
        ]}
        onClickRow={(row) => {
          setItem(row)
          setOpendDetail(true)
        }}        
        selectableRows={false}
      />
      <Modal
        isOpen={opendPayment}
        onClose={() => setOpendPayment(false)}
      >
        <ModalContent 
          title='로또 결과반영' 
          description='로또 결과반영 등록 관리 페이지입니다'
          data={{}} 
          columns={ [
            { label: '로또회차', name: 'lotto_seq', type: 'number', editable: true },
            { label: '첫번째 번호', name: 'lotto_num1', type: 'number', editable: true },
            { label: '두번째 번호', name: 'lotto_num2', type: 'number', editable: true },
            { label: '세번째 번호', name: 'lotto_num3', type: 'number', editable: true },
            { label: '네번째 번호', name: 'lotto_num4', type: 'number', editable: true }
          ]} 
          onSubmit={updateLottoNumber}
          onClose={() => setOpendPayment(false)}          
        />
      </Modal>

      <Modal
        isOpen={opendDetail}
        onClose={() => setOpendDetail(false)}
      >
        <ModalContent 
          title='로또 결과반영' 
          description='로또 결과반영 페이지입니다'
          data={item} 
          columns={ [
            { label: '로또회차', name: 'lotto_seq', type: 'text' },
            { label: '구매자', name: 'email_addr2.user_nm', type: 'text' },
            { label: '구매자이메일', name: 'email_addr', type: 'text' },
            { label: '당첨금', name: 'win_amt', type: 'number' },
            { label: '첫번째 번호', name: 'lotto_num1', type: 'text' },
            { label: '두번째 번호', name: 'lotto_num2', type: 'text' },
            { label: '세번째 번호', name: 'lotto_num3', type: 'text' },
            { label: '네번째 번호', name: 'lotto_num4', type: 'text' },
            { label: '구매일시', name: 'crt_dt', type: 'date' }
          ]} 
          onClose={() => setOpendDetail(false)}          
        />
      </Modal>      
    </ContentLayout>
  );
}
