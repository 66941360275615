export const formLabelKeys = {
  'D0001': { // 내 가게 광고
    ad_img_rep: '대표사진',
    ad_img: '상세사진',
    ad_subj: '제목',
    ad_cont: '내용',
    ad_keyword: '키워드',
    cmpy_type: '업종',
    addr1: '매장위치',
    addr2: '매장 주소 상세',
    area_type: '지역선택구분',
    ad_dist: '반경거리(미터)',
    discnt_prc: '할인가격',
    discnt_type: '할인행사종류',
    prod_link: '링크',
    perd_type: '기간구분',
    exp_perd: '광고집행기간',
    budget_type: '일예산구분',
    budget: '일 예산'
  },
  'D0002': { // 오프라인 할인
    ad_subj: '제목',
    ad_cont: '내용',
    ad_img_rep: '대표사진',
    ad_img: '상세사진',
    shop_cd: '매장 코드',
    shop_nm: '매장명',
    prod_nm: '제품명',
    cmpy_type: '업종',
    discnt_type: '할인행사종류',
    list_prc: '가격',
    ad_keyword: '키워드',
    addr1: '매장위치',
    addr2: '매장 주소 상세',
    discnt_cond: '할인행사 조건',
    branch_nm: '지점명',
    exp_perd: '유효기간'
  },
  'D0003': { // 온라인 할인
    ad_subj: '제목',
    ad_cont: '내용',
    ad_img_rep: '대표사진',
    ad_img: '상세사진',
    prod_nm: '제품명',
    shop_cd: '쇼핑몰 코드',
    shop_nm: '쇼핑몰',
    list_prc: '정가',
    discnt_prc: '할인가격',
    prod_link: '링크',
    ad_keyword: '키워드',
    discnt_cd: '할인코드'
  },
  'D0004': { // 할인 쿠폰
    ad_subj: '제목',
    ad_cont: '내용',
    ad_img_rep: '대표사진',
    ad_img: '상세사진',
    prod_nm: '제품명',
    shop_cd: '브랜드명 코드',
    shop_nm: '브랜드명/가게명',
    discnt_prc: '할인가격',
    ad_keyword: '키워드',
    addr1: '매장위치',
    addr2: '매장 주소 상세',
    discnt_cd: '할인코드',
    prod_link: '링크',
    exp_perd: '유효기간'
  }
}