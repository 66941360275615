import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { Modal } from '../shared/Modal';
import { HOST_URL } from '../shared/env';
import { ModalContent } from '../shared/ModalContent';
import { convertDatetime } from '../shared/date';
import { convertNumberComma } from '../shared/comma';

export function LottoCreate() {

  const navigate = useNavigate()

  const [item, setItem] = useState({
    lotto_seq: 0,
    lottery_time: new Date(),
    ttl_amt: 0
  })

  const [opendCreate, setOpendCreate] = useState(false)
  const [opendDetail, setOpendDetail] = useState(false)

  const createLotto = async (data) => {

    if (!data.lottery_time) {
      alert('시간을 추가해주세요')
    } else {

      await axios.post(`${HOST_URL}/app/appLottoCreate`, {
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        lotto_seq: +data.lotto_seq,
        lottery_time: data.lottery_time,
        ttl_amt: +data.ttl_amt
      })

    }

    setOpendCreate(false)
    navigate(0)

  }

  return (
    <ContentLayout 
      title='로또등록' 
      description='로또 등록 관리 페이지입니다'
      buttons={[
        { label: '번호등록', onClick: () => setOpendCreate(true) }
      ]}
    >

      <Table
        apiUrl={`${HOST_URL}/app/appAdminLottoList`}
        listName='lottos'
        countName='totalCount'
        columns={[
          { name: '로또회차', selector: row => row.lotto_seq },
          { name: '로또 추첨일', selector: row => convertDatetime(row.lottery_time) },
          { name: '첫번째 번호', selector: row => row.lotto_num1 },
          { name: '두번째 번호', selector: row => row.lotto_num2 },
          { name: '세번째 번호', selector: row => row.lotto_num3 },
          { name: '네번째 번호', selector: row => row.lotto_num4 },
          { name: '총액', selector: row => convertNumberComma(row.ttl_amt) },
          { name: '마감여부', selector: row => row.close_flag },       
        ]}
        searchs={[
          { label: '로또회차', value: 'lotto_seq' }
        ]}
        onClickRow={(row) => {
          setItem(row)
          setOpendDetail(true)
        }}        
        selectableRows={false}
      />
      <Modal
        isOpen={opendCreate}
        onClose={() => setOpendCreate(false)}
      >
        <ModalContent 
          title='로또등록' 
          description='로또 등록 관리 페이지입니다'
          data={{}} 
          columns={ [
            { label: '로또회차', name: 'lotto_seq', type: 'number', editable: true },
            { label: '로또 추첨일', name: 'lottery_time', type: 'date', editable: true },
            { label: '총액', name: 'ttl_amt', type: 'number', editable: true }
          ]} 
          onSubmit={createLotto}
          onClose={() => setOpendCreate(false)}          
        />
      </Modal>

      <Modal
        isOpen={opendDetail}
        onClose={() => setOpendDetail(false)}
      >
        <ModalContent 
          title='로또등록 정보' 
          description='로또 등록 정보 페이지입니다'
          data={item} 
          columns={ [
            { label: '로또회차', name: 'lotto_seq', type: 'text' },
            { label: '첫번째 번호', name: 'lotto_num1', type: 'text' },
            { label: '두번째 번호', name: 'lotto_num2', type: 'text' },
            { label: '세번째 번호', name: 'lotto_num3', type: 'text' },
            { label: '네번째 번호', name: 'lotto_num4', type: 'text' },
            { label: '로또 추첨일', name: 'lottery_time', type: 'date' },
            { label: '총액', name: 'ttl_amt', type: 'number' },
            { label: '마감여부', name: 'close_flag', type: 'text'}
          ]} 
          onClose={() => setOpendDetail(false)}          
        />
      </Modal>      
    </ContentLayout>
  );
}
