import React, { useState } from 'react';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { HOST_URL } from '../shared/env'
import { convertNumberComma } from '../shared/comma';

export function BudgetTotal() {

  const [apiType, setApiType] = useState('daily')

  return (
    <ContentLayout 
      title='결제 집계' 
      description='결제 집계 페이지입니다'
      buttons={[
        { label: apiType === 'daily' ? '월별 집계' : '일별 집계', onClick: () => setApiType(apiType === 'daily' ? 'monthly' : 'daily') }
      ]}            
    >
      <Table
        apiUrl={`${HOST_URL}/app/appAdminBudgetTotalList`}
        apiType={apiType}        
        listName='totals'
        countName='totalCount'
        columns={[
          { name: '', selector: row => '' }, 
          { name: '일자', selector: row => row.date },
          { name: '충전', selector: row => convertNumberComma(row.normal_charge_sum) },
          { name: '자동충전', selector: row => convertNumberComma(row.auto_charge_sum) },
          { name: '충전 합산', selector: row => convertNumberComma(row.charge_sum) },
          { name: '환불', selector: row => convertNumberComma(row.refund_sum) }
        ]}
        selectableRows={false}
        paginationServer={false}
      />
    </ContentLayout>
  );
}
