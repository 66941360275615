import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { Modal } from '../shared/Modal';
import { HOST_URL } from '../shared/env'
import { ModalContent } from '../shared/ModalContent';
import { adStoreColumns, adCouponColumns, adOfflineColumns, adOnlineColumns } from '../shared/adColumns.js'
import { convertDatetime } from '../shared/date';
import { formLabelKeys } from '../shared/formLabelKeys'

export function AdModify() {

  const navigate = useNavigate()

  const [item, setItem] = useState(undefined)
  const [selectedItem, setSelectedItem] = useState(undefined)
  const [opend, setOpend] = useState(false)
  const [ad_codes, setAdCodes] = useState([])
  const [prod_codes, setProdCodes] = useState([])
  const [cmpy_codes, setCmpyCodes] = useState([])
  const [discnt_codes, setDiscntCodes] = useState([])  

  const [formShopNm, setFormShopNm] = useState('')
  const [enabledValue, setEnabledValue] = useState(false)

  const updateAdDelete = async (seq) => {

    await axios.delete(`${HOST_URL}/app/appBizAdDelete`, {
      data: {
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        ad_seq: seq
      }
    })

    navigate(0)
    
    alert('광고가 삭제되었습니다')

  }  

  const updateAdModify = async (data) => {

    const checkImage = (name) => {

      const afterImage = document.querySelector('#' + name).getAttribute('src')

      return data[name] === afterImage ? data[name] : afterImage
    }

    data = {
      ...data,
      ad_img_rep: checkImage('ad_img_rep'),
      ad_img1: checkImage('ad_img1'),
      ad_img2: checkImage('ad_img2'),
      ad_img3: checkImage('ad_img3'),
      ad_img4: checkImage('ad_img4'),
      ad_img5: checkImage('ad_img5'),                        
      ad_img6: checkImage('ad_img6'),
      ad_img7: checkImage('ad_img7'),
      ad_img8: checkImage('ad_img8'),
      ad_img9: checkImage('ad_img9'),
      ad_img10: checkImage('ad_img10')                              
    }

    await axios.post(`${HOST_URL}/app/appBizAdRegist`, {
      lang: 'ko',
      token: localStorage.getItem('admin_token'),
      ad_seq: item.ad_seq,
      ...data
    })

    setOpend(false)
    navigate(0)

  }  

  const getCodeName = (codes, data) => {
    if (data && codes.length) {
      const prod = codes.find(e => e.dtl_cd_id === data)
      return prod ? prod.dtl_cd_nm : data
    }
  }

  const filteredContentColumns = (adType) => {

    switch(adType) {
      case 'D0001' : // 내 가게 광고
        return adStoreColumns
      case 'D0002' : // 오프라인 할인
        return adOfflineColumns
      case 'D0003' : // 온라인 할인
        return adOnlineColumns
      case 'D0004' : // 할인 쿠폰
        return adCouponColumns
      default : return []
    }

  }

  const changeFormShopNm = (e) => {

    const value = prod_codes.find(code => code.dtl_cd_id === e.target.value).dtl_cd_nm

    setFormShopNm(e.target.value != 'D0036' ? value : '')

    setTimeout(() => {
      setEnabledValue(e.target.value != 'D0036')

      document.querySelector('[name="shop_nm"]').focus()
    }, 10)
  }

  const getAdTypeNameLabel = (data, e) => {

    return formLabelKeys[data.ad_type][e.name]

  }

  useEffect(() => {

    (async () => {

      const respAdCodes = await axios.post(`${HOST_URL}/app/appCodeList`, {
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        mst_cd_id: 'M0001'
      })

      setAdCodes(respAdCodes.data.result)

      const respCmpyCodes = await axios.post(`${HOST_URL}/app/appCodeList`, {
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        mst_cd_id: 'M0002'
      })
  
      setCmpyCodes(respCmpyCodes.data.result)

      const respDiscntCodes = await axios.post(`${HOST_URL}/app/appCodeList`, {
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        mst_cd_id: 'M0004'
      })
  
      console.log(respDiscntCodes.data.result)
      setDiscntCodes(respDiscntCodes.data.result)


    })()

  }, [])  

  return (
    <ContentLayout 
      title='광고수정' 
      description='사용자가 등록한 광고 수정 페이지입니다'
      buttons={[
        { label: '광고삭제', onClick: () => updateAdDelete(selectedItem.ad_seq)}
      ]}      
    >
      <Table
        apiUrl={`${HOST_URL}/app/appAdminAdList`}
        apiType='delete'
        listName='ads'
        countName='totalCount'
        columns={[
          { name: '광고번호', selector : row => row.ad_seq },
          { name: '광고유형', selector: row => ad_codes.length && ad_codes.find(e => e.dtl_cd_id === row.ad_type).dtl_cd_nm },  
          { name: '광고명', selector: row => row.ad_subj },
          { name: '제품명', selector: row => row.prod_nm },
          { name: '등록자', selector: row => row.reg_email_addr2.user_nm },
          { name: '등록자 이메일', selector: row => row.reg_email_addr },          
          { name: '광고상태', selector: row => {
            switch(row.state_flag){
              case 'R': return '대기(R)'
              case 'P': return '진행중(P)'
              case 'S': return '중지(S)'
              case 'E': return '마감(E)'
              case 'D': return '삭제(D)'
              default: return ''
            }
          } },
          { name: '등록일', selector: row => convertDatetime(row.crt_dt) }
        ]}
        searchs={[
          { label: '광고번호', value: 'ad_seq' },
          { label: '광고명', value: 'ad_subj' },
          { label: '광고상태', value: 'state_flag' },
          { label: '광고유형', value: 'ad_type', options: ad_codes.map(e => ({ label: e.dtl_cd_nm, value: e.dtl_cd_id })) },    
          { label: '이메일', value: 'reg_email_addr' },
        ]}
        onClickRow={(row) => {

          (async () => {

            const prodMstCdIdkeys = {
              'D0002': 'M0003',
              'D0003': 'M0005',
              'D0004': 'M0006'
            }
      
            const respProdCodes = await axios.post(`${HOST_URL}/app/appCodeList`, {
              lang: 'ko',
              token: localStorage.getItem('admin_token'),
              mst_cd_id: prodMstCdIdkeys[row.ad_type]
            })
        
            setProdCodes(respProdCodes.data.result)

            setItem(_ => row)
            setOpend(true)

          })()

        }}
        onSelectRows={(row) => {
          setSelectedItem(row[0])
        }}
        selectableRows={true}
        selectableRowsSingle={true}
      />
      <Modal
        isOpen={opend}
        onClose={() => setOpend(false)}
      >
        <ModalContent 
          title='광고수정' 
          description='사용자가 등록한 광고 수정 페이지입니다'
          data={item}
          convertLabel={getAdTypeNameLabel}
          columns={item && [...filteredContentColumns(item.ad_type), 'state_flag'].map(x => {
            const columns = [
              { label: '광고유형', name: 'ad_type', type: 'text', selector: row => ad_codes.length && ad_codes.find(e => e.dtl_cd_id === row.ad_type).dtl_cd_nm }, 
              { label: '등록자', name: 'reg_email_addr2.user_nm', type: 'text' },            
              { label: '등록자 이메일', name: 'reg_email_addr', type: 'text' }, 
              { label: '광고번호', name: 'ad_seq' },
              { label: '광고명', name: 'ad_subj', type: 'text', editable: true },
              { label: '광고내용', name: 'ad_cont', type: 'textarea', editable: true },
              { label: '대표사진', name: 'ad_img_rep', type: 'image', editable: true},
              { label: '상세사진1', name: 'ad_img1', type: 'image', editable: true },
              { label: '상세사진2', name: 'ad_img2', type: 'image', editable: true },
              { label: '상세사진3', name: 'ad_img3', type: 'image', editable: true },
              { label: '상세사진4', name: 'ad_img4', type: 'image', editable: true },
              { label: '상세사진5', name: 'ad_img5', type: 'image', editable: true },
              { label: '상세사진6', name: 'ad_img6', type: 'image', editable: true },
              { label: '상세사진7', name: 'ad_img7', type: 'image', editable: true },
              { label: '상세사진8', name: 'ad_img8', type: 'image', editable: true },
              { label: '상세사진9', name: 'ad_img9', type: 'image', editable: true },
              { label: '상세사진10', name: 'ad_img10', type: 'image', editable: true },
              { label: '가게 코드', name: 'shop_cd', type: 'select', editable: true, selectOptions: prod_codes.map((e) => ({ label: `${e.dtl_cd_nm}(${e.dtl_cd_id})`, value: e.dtl_cd_id })), onChangeSelectOption: changeFormShopNm }, 
              { label: '가게명', name: 'shop_nm', type: 'text', editable: true, enabledValue: enabledValue, reValue: formShopNm },
              { label: '제품명', name: 'prod_nm', type:  'text', editable: true },
              { label: '업종', name: 'cmpy_type', type: 'select', editable: true, selectOptions: cmpy_codes.map((e) => ({ label: e.dtl_cd_nm, value: e.dtl_cd_id })) },
              { label: '할인행사종류', name: 'discnt_type', type: 'select', editable: true, selectOptions: discnt_codes.map((e) => ({ label: e.dtl_cd_nm, value: e.dtl_cd_id })) },
              { label: '할인가격', name: 'discnt_prc', type: 'number', editable: true },
              { label: '키워드', name: 'ad_keyword', type: 'text', editable: true },
              { label: '매장위치', name: 'addr1', type: 'text', editable: true },
              { label: '세부주소', name: 'addr2', type: 'text', editable: true },
              { label: '할인코드', name: 'discnt_cd', type: 'text', editable: true},
              { label: '정가', name: 'list_prc', type: 'number', editable: true },
              { label: '할인행사 조건', name: 'discnt_cond', type: 'text', editable: true },
              { label: '지점명', name: 'branch_nm', type: 'text', editable: true },
              { label: '링크', name: 'prod_link', type: 'text', editable: true},
              { label: '유효기간', name: 'exp_perd', type: 'text', editable: true },
              { label: '광고상태', name: 'state_flag', type: 'select', editable: true, selectOptions: [
                { label: '대기(R)', value: 'R'},
                { label: '진행중(P)', value: 'P'},
                { label: '중지(S)', value: 'S'},
                { label: '마감(E)', value: 'E'},
                { label: '삭제(D)', value: 'D'}
              ]},            
              { label: '승인여부', name: 'use_yn', type: 'select', editable: true, selectOptions: [
                { label: '미승인(N)', value: 'N' },
                { label: '승인(Y)', value: 'Y'},
                { label: '반려(X)', value: 'X'},
              ] },
              { label: '등록일', name: 'crt_dt', type:'date' },
              { label: '지역선택구분', name: 'area_type', type: 'select', editable: true, selectOptions: [
                { label: '지역선택', value: '1' },
                { label: '주변범위', value: '2' },
                { label: '지역선택안함', value: '3' }
              ]},
              { label: '반경거리(미터)', name: 'ad_dist', type: 'number', editable: true },
              { label: '기간구분', name: 'perd_type', type: 'select', editable: true, selectOptions: [
                { label: '지금시작', value: '1' },
                { label: '예약', value: '2' },
                { label: '종료일 없음', value: '3' }
              ] },
              { label: '광고시작일시', name: 'ad_begin', type: 'date', editable: true },
              { label: '광고종료일시', name: 'ad_end', type: 'date', editable: true },
              { label: '일예산구분', name: 'budget_type', type: 'select', editable: true, selectOptions: [
                { label: '직적입력', value: '1' },
                { label: '추천예산', value: '2' }
              ] },
              { label: '일 예산', name: 'budget', type: 'number', editable: true }         
            ]

            return columns.find(y => y.name === x)

          })}
          submitLabel='수정'
          onSubmit={updateAdModify}
          onClose={() => setOpend(false)}
        />
      </Modal>
    </ContentLayout>
  );
}
