import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ContentLayout } from '../shared/content-layout';
import { HOST_URL } from '../shared/env';
import axios from 'axios';
import styled from '@emotion/styled';

const DataTable = ({ data }) => {
  return (
    <div>
    <table>
      <thead>
      </thead>
      <tbody>
        {Object.entries(data).map(([key, value]) => (
          <tr key={key}>
            <td><Label>{key}</Label></td>
            <td><Label>{value}</Label></td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
};

export function OcrTest() {

  const { register, handleSubmit, watch } = useForm()
  const [idcardImage, setIdCardImage] = useState('')
  const [data, setData] = useState({ })

  const watchIdCardImage = watch('idcard_image')

  const onSubmit = async (e) => {

    const form = new FormData()
    form.append('lang', 'ko')
    form.append('token', localStorage.getItem('admin_token'))
    form.append('type', e.type)
    form.append('idcard_image', e.idcard_image[0])

    const { data } = await axios({
      method: 'post',
        url: `${HOST_URL}/app/idCardOcr`,
      data: form,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    setData(data)

  }

  useEffect(() => {

    if (watchIdCardImage) {

      setIdCardImage(watchIdCardImage[0])

    }

  }, [watchIdCardImage])

  return (
    <ContentLayout 
      title='OCR 테스트' 
      description='OCR 테스트 페이지입니다'
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        
        <ImageGroup>
          <div>
            <Image src={idcardImage ? URL.createObjectURL(idcardImage) : ''} />
            <label>
              <input defaultChecked {...register('type')} value={'1'} type='radio' />
              주민등록증
            </label>
            <br />
            <label>
              <input {...register('type')} value={'2'} type='radio' />
              운전면허증
            </label>
            <br />
            <label>
              <input {...register('type')} value={'3'} type='radio' />
              외국인등록증
            </label>
            <br />
            <p />
            <input {...register('idcard_image')} type='file' />
          </div>
        </ImageGroup>

        <p />
        <button>전송</button>
      </form>

      <div>
        <h3>결과</h3>
        {!!(Object.keys(data).length) && 
        <div>
          <Table data={data} />
        </div>}
      </div>

      <div>
        <h3>신분증 이미지 <Small>(눌러서 저장)</Small></h3>
        <Img alt='ic' src='/images/ic_image.png' />
        <Img alt='dl' src='/images/dl_image.png' />
        <Img alt='ac' src='/images/ac_image.png' />
        <Img alt='pp' src='/images/pp_image.png' />
      </div>
    </ContentLayout>
  )
}

const ImageGroup = styled.div`
display: flex;
div {
  width: 50%;
}
`

const Image = styled.img`
width: 100%;
`

const Table = styled(DataTable)`
div {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
  

@media screen and (max-width: 600px) {
  th, td {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  th, td {
    font-size: 12px;
  }
}
`

const Label = styled.div`
word-break: break-all;
`


const Img = styled.img`
width: 200px;
margin: 12px;
display: block;
`

const Small = styled.small`
color: #666;
`