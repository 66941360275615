import styled from "@emotion/styled"
import { Header, HeaderInfo, DataViewerTable } from "./styled"
import { useForm } from "react-hook-form"
import { convertDatetime } from "./date"
import { convertNumberComma } from '../shared/comma'
import axios from "axios"
import { HOST_URL } from "./env"
import dayjs from "dayjs"

function ContentInput({ placeholder='', name, register, value, type, editable = false, selectOptions = [], apiUrl = '', onChangeSelectOption, enabledValue }) {

  if (!editable && type !== 'select') {
    if (type === 'image') return <Img src={value} />
    if (type === 'textarea') return <TextareaViewer readOnly={true}>{value}</TextareaViewer>
    if (type === 'number') return <div>{value ? convertNumberComma(value) : 0}</div>
    if (type === 'date') return <div>{value ? convertDatetime(value) : ''}</div>
    return <div>{value}</div>
  }

  switch(type) {
    case 'text':
      return <Input {...(editable && register)} defaultValue={value} placeholder={placeholder} { ...(enabledValue ? { value } : {})} />
    case 'textarea':
      return <Textarea {...(editable && register)} defaultValue={value} placeholder={placeholder}></Textarea>
    case 'number':
      return <Input {...(editable && register)} type='number' defaultValue={value} placeholder={placeholder}/>
    case 'image':
      return <div>
        <Img id={name} src={value || ''} />
        <br />
        <input {...(editable && register ) } defaultValue={value} type='hidden' />
        <input id={name + '_file'} type='file' onChange={ async (e) => {

          if (e.target.files.length) {

            const formData = new FormData()
            formData.append('files', e.target.files[0])
        
            const resp = await axios({
              method: 'post',
                url: `${HOST_URL}${apiUrl ? apiUrl : '/app/upload'}`,
              data: formData,
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })

            document.querySelector('#' + name).src = resp.data.result[0]

          } else {

            document.querySelector('#' + name).src = document.querySelector(`input[name='${name}']`).value
            document.querySelector('#' + name + '_file').value = ''
          }

        }} />
        <button onClick={() => {
          document.querySelector('#' + name).src = document.querySelector(`input[name='${name}']`).value
          document.querySelector('#' + name + '_file').value = ''
        }} type="button">리셋</button>
        <button onClick={() => {
          document.querySelector('#' + name).src = ''
          document.querySelector('#' + name + '_file').value = ''
        }} type="button">삭제</button>        
      </div>
    case 'select':
      return <Select {...(editable && register)} disabled={!editable} defaultValue={value} onChange={(e) => {
        if (onChangeSelectOption) onChangeSelectOption(e)
      }}>
        {selectOptions.map((e, i) => <option key={i} value={e.value}>{e.label}</option>)}
      </Select>
    case 'date':
      return <Input type='datetime-local' {...(editable && register)} defaultValue={value ? dayjs(value.replace('Z', '')).format('YYYY-MM-DD HH:mm:ss') : ''} />
    default:
      return <div>{value}</div>
  }

}

export function ModalContent({ title, description, columns, data, onClose, onSubmit, submitLabel = '확인', convertLabel }) {

  const { register, handleSubmit } = useForm()

  const getData = (data, name) => {
    const props = name.split('.')

    let result = data
  
    for (let i = 0; i < props.length; i++) result = result[props[i]]
  
    return result
  }

  return (<Form onSubmit={handleSubmit((data) => onSubmit(Object.keys(data).reduce((acc, cur) => {
    if (columns.find(e => e.name === cur) && columns.find(e => e.name === cur).editable) acc[cur] = data[cur]
    return acc
  }, {})))}>
    <Header>
      <HeaderInfo>
        <h2>{title}</h2>
        <h5>{description}</h5>
      </HeaderInfo>
    </Header>

    { columns.length && <DataViewerTable>
      {columns.map((e, i) => (!e || ((!e.editable) && e.type === 'image' && !getData(data, e.name)) ) ? <tr></tr> : <tr key={i}>
        <td>
          <TdLabel>
          {(convertLabel && convertLabel(data, e)) || e.label}
          </TdLabel>
        </td>
        <td>
          <TdValue>
            <ContentInput 
              placeholder={e.placeholder}
              name={e.name}
              register={register(e.name)}
              value={e.enabledValue ? e.reValue : (e.selector ? e.selector(data) : getData(data, e.name))}
              type={e.type}
              editable={e.editable}
              selectOptions={ e.type === 'select' ? e.selectOptions : [] }
              apiUrl={e.apiUrl}
              onChangeSelectOption={e.type === 'select' ? e.onChangeSelectOption : null }
              enabledValue={e.enabledValue}
            />
          </TdValue>
        </td>
      </tr>)}
    </DataViewerTable>}

    <FloatingButtonGroup>
      {
        onSubmit ? <>
          <ButtonItem type='button' value={'닫기'} onClick={onClose} />
          <ButtonItem type='submit' value={submitLabel} />
        </> : <ButtonLargeItem type='button' value={'닫기'} onClick={onClose} />
      }

    </FloatingButtonGroup>

  </Form>)

}

const Form = styled.form`
display: flex;
height: 100%;
flex-direction: column;
justify-content: space-betwenn;
padding: 20px;
padding-right: 5px;
td {
  width: fit-content;
}

img[src=''] {
  display:none;
}
`

const FloatingButtonGroup = styled.div`
background-color: #FFCE3E;
display: flex;
position: fixed;
bottom: 0;
left: 0;
width: 100%;
`

const ButtonItem = styled.input`
background-color: #FFCE3E;
cursor: pointer;
border: none;
width: 50%;
height: 40px;
border: .5px solid rgba(0,0,0,0.1);
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
`

const ButtonLargeItem = styled.input`
background-color: #FFCE3E;
cursor: pointer;
border: none;
width: 100%;
height: 40px;
border: .5px solid rgba(0,0,0,0.1);
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
`

const TdLabel = styled.div`
width: 130px;
font-weight: 500;
color: rgba(0,0,0,0.5);
`

const TdValue = styled.div`
width: 100%;
`

const Textarea = styled.textarea`
box-sizing: border-box;
width: 100%;
height: 150px;
padding: 15px;
border-radius: 5px;
background-color: rgb(235, 235, 235);
border: 0px;
outline: none;
font-size: 14px;
font-weight: 500;
text-align: left;
border: 2px solid transparent;

:focus {
  border: 2px solid rgb(0, 100, 255);
}
`

const TextareaViewer = styled.div`
white-space: pre-wrap;
`

const Img = styled.img`
max-width: 350px;
width: 100%;
`

const Input = styled.input`
text-align: left;
box-sizing: border-box;
width: 80%;
padding: 15px;
border-radius: 5px;
background-color: rgb(235, 235, 235);
border: 0px;
outline: none;
font-size: 14px;
font-weight: 500;
text-align: left;
border: 2px solid transparent;

:focus {
  border: 2px solid rgb(0, 100, 255);
}
`

const Select = styled.select`
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
box-sizing: border-box;
width: fit-content;
padding: 15px;
border-radius: 5px;
background-color: rgb(235, 235, 235);
border: 0px;
outline: none;
font-size: 14px;
font-weight: 500;
text-align: left;
margin-right: 8px;
border: 2px solid transparent;
:focus {
  border: 2px solid rgb(0, 100, 255);
}
`