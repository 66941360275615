import React, { useState } from 'react';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { HOST_URL } from '../shared/env'
import { convertNumberComma } from '../shared/comma';

export function CoinTotal() {

  const [apiType, setApiType] = useState('daily')

  return (
    <ContentLayout 
      title='코인 집계' 
      description='코인 집계 페이지입니다'
      buttons={[
        { label: apiType === 'daily' ? '월별 집계' : '일별 집계', onClick: () => setApiType(apiType === 'daily' ? 'monthly' : 'daily') }
      ]}      
    >
      <Table
        apiUrl={`${HOST_URL}/app/appAdminCoinTotalList`}
        apiType={apiType}
        listName='totals'
        countName='totalCount'
        columns={[
          { name: '', selector: row => '' },          
          { name: '일자', selector: row => row.date },
          { name: '총 획득', selector: row => convertNumberComma(row.inc_sum) },
          { name: '총 지출', selector: row => convertNumberComma(row.mns_sum) }
        ]}
        selectableRows={false}
        paginationServer={false}
      />
    </ContentLayout>
  );
}
