import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { Modal } from '../shared/Modal';
import { HOST_URL } from '../shared/env'
import { ModalContent } from '../shared/ModalContent';
import axios from 'axios';
import { convertDatetime } from '../shared/date';

export function UserMgt() {

  const navigate = useNavigate()  

  const [item, setItem] = useState(undefined)
  const [opend, setOpend] = useState(false)

  const updateUser = async (user) => {

    await axios.post(`${HOST_URL}/app/appAdminUserUpdate`, {
      lang: 'ko',
      token: localStorage.getItem('admin_token'),
      email_addr: item.email_addr,
      update_data: user
    })    

    setOpend(false)
    navigate(0)

  }  

  return (
    <ContentLayout 
      title='회원 관리'
      description='회원 관리 페이지입니다'
      buttons={[
      ]}
    >
      <Table
        apiUrl={`${HOST_URL}/app/appAdminUserList`}
        listName='users'
        countName='totalCount'
        columns={[
          { name: '닉네임', selector: row => row.user_nick_nm },          
          { name: '이름', selector: row => row.user_nm },
          { name: '이메일', selector: row => row.email_addr },
          { name: '소셜가입정보', selector: row => row.entry_site },
          { name: '전화번호', selector: row => row.phone_num },
          { name: '성별', selector: row => row.sex },
          { name: '가입일', selector: row => convertDatetime(row.crt_dt), selector: row => convertDatetime(row.crt_dt) },
        ]}
        searchs={[
          { label: '이메일', value: 'email_addr' },          
          { label: '이름', value: 'user_nm' },
          { label: '닉네임', value: 'user_nick_nm' },     
          { label: '소셜가입정보', value: 'entry_site' },
          { label: '성별', value: 'sex'},
          { label: '전화번호', value: 'phone_num' },
        ]}
        onClickRow={(row) => {
          setItem(_ => row)
          setOpend(true)
        }}
        selectableRows={false}
      />
      <Modal
        isOpen={opend}
        onClose={() => setOpend(false)}
      >
        <ModalContent 
          title='회원 관리' 
          description='회원 관리 페이지입니다'
          data={item}
          columns={ [
            { label: '프로필', name: 'photo', type: 'image' },
            { label: '닉네임', name: 'user_nick_nm', type: 'text', editable: true },
            { label: '이름', name: 'user_nm', type: 'text', editable: true },
            { label: '이메일', name: 'email_addr', type: 'text' },
            { label: '소셜가입정보', name: 'entry_site', type: 'text' },
            { label: '성별', name: 'sex', type: 'text', editable: true },
            { label: '전화번호', name: 'phone_num', type: 'text' },
            { label: '가입일', name: 'crt_dt', type: 'date' },
          ]} 
          onSubmit={updateUser}
          onClose={() => setOpend(false)}
        />        
      </Modal>
    </ContentLayout>
  );
}
