import { AppLayout } from './app-layout'

import { Login } from './pages/login'
import { MainLayout } from './pages/main-layout'
import { UserMgt } from './pages/user-mgt'
import { UserGrade } from './pages/user-grade'
import { UserTotal } from './pages/user-total'
import { AdConfirm } from './pages/ad-confirm'
import { AdModify } from './pages/ad-modify'
import { AdReport } from './pages/ad-report'
import { AdTotal } from './pages/ad-total'
import { LottoCreate } from './pages/lotto-create'
import { LottoPayment } from './pages/lotto-payment'
import { CoinMgt } from './pages/coin-mgt'
import { CoinTotal } from './pages/coin-total'
import { PointWithdrawal } from './pages/point-withdrawal'
import { PointMgt } from './pages/point-mgt'
import { PointTotal } from './pages/point-total'
import { BudgetMgt } from './pages/budget-mgt'
import { BudgetReject } from './pages/budget-reject'
import { BudgetTotal } from './pages/budget-total'
import { CommentMgt } from './pages/comment-mgt'
import { BoardNotice } from './pages/board-notice'
import { BoardFaq } from './pages/board-faq'
import { BoardQna } from './pages/board-qna'
import { Home } from './pages/home'
import { AdNewStore } from './pages/ad-new-store'
import { AdNewCoupon } from './pages/ad-new-coupon'
import { AdNewOnline } from './pages/ad-new-online'
import { AdNewOffline } from './pages/ad-new-offline'
import { FrTest } from './pages/fr-test'
import { OcrTest } from './pages/ocr-test'
import { BankOcrTest }from './pages/bank-ocr-test'
import { DocumentOcrTest } from './pages/document-ocr-test'
import { PushMgt } from './pages/push-mgt'
import { RecomGraph } from './pages/recom-graph'

const routes = [
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            path: '/',
            element: <Home />
          },          
          {
            path: 'user-mgt',
            element: <UserMgt />
          },          
          {
            path: 'user-grade',
            element: <UserGrade />
          },
          {
            path: 'user-total',
            element: <UserTotal />
          },
          {
            path: 'user-total',
            element: <UserTotal />
          },
          {
            path: 'ad-new',
            children: [
              {
                path: 'store',
                element: <AdNewStore />,
              },
              {
                path: 'coupon',
                element: <AdNewCoupon />,
              },
              {
                path: 'online',
                element: <AdNewOnline />,
              },
              {
                path: 'offline',
                element: <AdNewOffline />,
              }
            ]
          },
          {
            path: 'ad-confirm',
            element: <AdConfirm />
          },
          {
            path: 'ad-modify',
            element: <AdModify />
          },
          {
            path: 'ad-report',
            element: <AdReport />
          },
          {
            path: 'ad-total',
            element: <AdTotal />
          },
          {
            path: 'lotto-create',
            element: <LottoCreate />
          },
          {
            path: 'lotto-payment',
            element: <LottoPayment />
          },          
          {
            path: 'coin-mgt',
            element: <CoinMgt />
          },
          {
            path: 'coin-total',
            element: <CoinTotal />
          },          
          {
            path: 'point-withdrawal',
            element: <PointWithdrawal />
          },
          {
            path: 'point-mgt',
            element: <PointMgt />
          },
          {
            path: 'point-total',
            element: <PointTotal />
          },                    
          {
            path: 'budget-mgt',
            element: <BudgetMgt />
          },
          {
            path: 'budget-reject',
            element: <BudgetReject />
          },
          {
            path: 'budget-total',
            element: <BudgetTotal />
          },      
          {
            path: 'comment-mgt',
            element: <CommentMgt />
          },
          {
            path: 'board-notice',
            element: <BoardNotice />
          },
          {
            path: 'board-faq',
            element: <BoardFaq />
          },
          {
            path: 'board-qna',
            element: <BoardQna />
          },
          {
            path: 'push-mgt',
            element: <PushMgt />
          },          
          {
            path: 'fr-test',
            element: <FrTest />
          },
          {
            path: 'document-ocr-test',
            element: <DocumentOcrTest />
          },          
          {
            path: 'ocr-test',
            element: <OcrTest />
          },
          {
            path: 'bank-ocr-test',
            element: <BankOcrTest />
          }
        ]
      },      
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'recom-graph',
        element: <RecomGraph />
      }
    ]
  },
];

export { routes };
