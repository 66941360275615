import React, { useEffect, useState } from 'react';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { Modal } from '../shared/Modal';
import { ModalContent } from '../shared/ModalContent';
import { HOST_URL } from '../shared/env'
import axios from 'axios';
import { convertDatetime } from '../shared/date';
import { convertNumberComma } from '../shared/comma';

export function BudgetReject() {

  const [item, setItem] = useState(undefined)
  const [opend, setOpend] = useState(false)

  const [budget_codes, setBudgetCodes] = useState([])

  const getCodeName = (codes, data) => {
    if (data && codes.length) {
      const prod = codes.find(e => e.dtl_cd_id === data)
      return prod ? prod.dtl_cd_nm : data
    }
  }  

  useEffect(() => {

    (async () => {

      const respBudgetCodes = await axios.post(`${HOST_URL}/app/appCodeList`, {
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        mst_cd_id: 'M0012'
      })

      setBudgetCodes(respBudgetCodes.data.result)

    })()
  
  }, [])

  return (
    <ContentLayout 
      title='결제 취소 관리'
      description='결제 취소 관리 페이지입니다'
      buttons={[
      ]}
    >

      <Table
        apiUrl={`${HOST_URL}/app/appAdminBudgetList`}
        apiType='reject'
        listName='budgets'
        countName='totalCount'
        columns={[
          { name: '결제 이력번호', selector: row => row.budget_hist_seq },
          { name: '이름', selector: row => row.email_addr2.user_nm },
          { name: '이메일', selector: row => row.email_addr },
          { name: '결제 금액', selector: row => convertNumberComma(row.budget_amt) },
          { name: '결제적립유형', selector: row => getCodeName(budget_codes, row.budget_get_type) },
          { name: '등록일', selector: row => convertDatetime(row.crt_dt) }
        ]}
        searchs={[
          { label: '이메일', value: 'email_addr' },
          { label: '결제적립유형', value: 'budget_get_type' }
        ]}
        onClickRow={(row) => {
          setItem(row)
          setOpend(true)
        }}
        selectableRows={false}
      />
      <Modal
        isOpen={opend}
        onClose={() => setOpend(false)}
      >
        <ModalContent 
          title='결제 취소 관리' 
          description='결제 취소 관리 페이지입니다'
          data={item}
          columns={ [
            { label: '결제 이력번호', name: 'budget_hist_seq', type: 'text'},
            { label: '이름', name: 'email_addr2.user_nm', type: 'text'},
            { label: '이메일', name: 'email_addr', type: 'text' },
            { label: '결제 금액', name: 'budget_amt', type: 'number' },
            { label: '결제적립유형', name: 'budget_get_type', type: 'text', selector: row => getCodeName(budget_codes, row.budget_get_type) },
            { label: '등록일', name: 'crt_dt', type: 'date'},
          ]}
          onClose={() => setOpend(false)}
        />        
      </Modal>
    </ContentLayout>
  );
}
