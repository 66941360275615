export const adStoreColumns = [
  'ad_type', 
  'reg_email_addr2.user_nm', 
  'reg_email_addr', 
  'ad_seq', 
  'ad_img_rep', 
  'ad_img1', 
  'ad_img2', 
  'ad_img3', 
  'ad_img4', 
  'ad_img5', 
  'ad_img6', 
  'ad_img7', 
  'ad_img8', 
  'ad_img9', 
  'ad_img10',  
  'ad_subj', 
  'ad_cont', 
  'ad_keyword',
  'addr1',
  'addr2',  
  'discnt_prc',
  'discnt_type',  
  'prod_link',
  'area_type',
  'ad_dist',
  'perd_type',
  'ad_begin',
  'ad_end',
  'budget_type',
  'budget',
]

export const adOfflineColumns = [
  'ad_type', 
  'reg_email_addr2.user_nm', 
  'reg_email_addr', 
  'ad_seq', 
  'ad_subj', 
  'ad_cont', 
  'ad_img_rep', 
  'ad_img1', 
  'ad_img2', 
  'ad_img3', 
  'ad_img4', 
  'ad_img5', 
  'ad_img6', 
  'ad_img7', 
  'ad_img8', 
  'ad_img9', 
  'ad_img10',
  'shop_cd',
  'shop_nm', 
  'prod_nm', 
  'cmpy_type', 
  'discnt_type', 
  'list_prc', 
  'ad_keyword', 
  'addr1', 
  'discnt_cond', 
  'branch_nm', 
  'exp_perd',
]

export const adOnlineColumns = [
  'ad_type',
  'reg_email_addr2.user_nm',
  'reg_email_addr',
  'ad_seq',
  'ad_subj',
  'ad_cont',
  'ad_img_rep',
  'ad_img1',
  'ad_img2',
  'ad_img3', 
  'ad_img4', 
  'ad_img5', 
  'ad_img6', 
  'ad_img7', 
  'ad_img8', 
  'ad_img9', 
  'ad_img10', 
  'shop_cd',  
  'shop_nm', 
  'prod_nm', 
  'list_prc', 
  'discnt_prc', 
  'prod_link', 
  'ad_keyword', 
  'discnt_cd', 
  'exp_perd',
]

export const adCouponColumns = [
  'ad_type', 
  'reg_email_addr2.user_nm', 
  'reg_email_addr', 
  'ad_seq', 
  'ad_subj', 
  'ad_cont', 
  'ad_img_rep', 
  'ad_img1', 
  'ad_img2', 
  'ad_img3', 
  'ad_img4', 
  'ad_img5', 
  'ad_img6', 
  'ad_img7', 
  'ad_img8', 
  'ad_img9', 
  'ad_img10', 
  'shop_cd',  
  'shop_nm', 
  'prod_nm', 
  'discnt_prc', 
  'ad_keyword', 
  'addr1', 
  'discnt_cd', 
  'prod_link',
  'exp_perd',
]