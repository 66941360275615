import { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

export function AppLayout() {

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const token = localStorage.getItem('admin_token')

    if (!token && location.pathname !== '/login') navigate('login')

  }, [location.pathname])

  return (
    <div>
      <div>
        <Outlet />
      </div>
    </div>
  );
}