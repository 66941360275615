import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import axios from 'axios';
import { Modal } from '../shared/Modal';
import { HOST_URL } from '../shared/env'
import { ModalContent } from '../shared/ModalContent';
import { convertDatetime } from '../shared/date';

export function UserGrade() {

  const navigate = useNavigate()

  const [item, setItem] = useState(undefined)
  const [opend, setOpend] = useState(false)

  const updateUserGrade = async (user) => {

    await axios.post(`${HOST_URL}/app/appAdminUserUpdateGrade`, {
      lang: 'ko',
      token: localStorage.getItem('admin_token'),
      email_addr: item.email_addr,
      role_nm: user.role_nm
    })

    setOpend(false)
    navigate(0)

  }

  return (
    <ContentLayout 
      title='회원 등급 부여' 
      description='회원 등급 부여 관리 페이지입니다'
    >
      <Table
        apiUrl={`${HOST_URL}/app/appAdminUserList`}
        listName='users'
        countName='totalCount'
        columns={[     
          { name: '이름', selector: row => row.user_nm },     
          { name: '이메일', selector: row => row.email_addr },
          { name: '등급', selector: row => row.role_nm },
          { name: '소셜가입정보', selector: row => row.entry_site },
          { name: '가입일', selector: row => convertDatetime(row.crt_dt) },
        ]}
        searchs={[
          { label: '이메일', value: 'email_addr' },          
          { label: '이름', value: 'user_nm' },
          { label: '닉네임', value: 'user_nick_nm' },     
          { label: '소셜가입정보', value: 'entry_site' },                    
          { label: '등급', value: 'role_nm' }
        ]}
        onClickRow={(row) => {
          setItem(_ => row)
          setOpend(true)
        }}
        selectableRows={false}
      />
      <Modal
        isOpen={opend}
        onClose={() => setOpend(false)}
      >
        <ModalContent 
          title='회원 등급 부여' 
          description='회원 등급 부여 관리 페이지입니다'
          data={item}
          columns={ [
            { label: '프로필', name: 'photo', type: 'image' },
            { label: '닉네임', name: 'user_nick_nm', type: 'text' },
            { label: '이름', name: 'user_nm', type: 'text' },
            { label: '이메일', name: 'email_addr', type: 'text' },
            { label: '등급', name: 'role_nm', type: 'select', editable: true, selectOptions: [
              { label: 'ROLE_USER', value: 'ROLE_USER'},
              { label: 'ROLE_ADMIN', value: 'ROLE_ADMIN'},
            ] },
            { label: '소셜가입정보', name: 'entry_site', type: 'text' },
            { label: '가입일', name: 'crt_dt', type: 'date' },
          ]} 
          onSubmit={updateUserGrade}
          onClose={() => setOpend(false)}
        />
      </Modal>
    </ContentLayout>
  );
}
