import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ContentLayout } from '../shared/content-layout';
import { Table } from '../shared/Table';
import { Modal } from '../shared/Modal';
import { ModalContent } from '../shared/ModalContent';
import { HOST_URL } from '../shared/env'
import { convertDatetime } from '../shared/date';

export function PushMgt() {

  const navigate = useNavigate()

  const [item, setItem] = useState({
    subject: '',
    content: ''
  })
  const [opendTestPush, setOpendTestPush] = useState(false)
  const [opendNewPush, setOpendNewPush] = useState(false)
  const [opendEditPush, setOpendEditPush] = useState(false)

  const sendPushMessage = async () => {

    await axios.post(`${HOST_URL}/app/sendPushMessage`, {
      lang: 'ko',
      token: localStorage.getItem('admin_token')
    })

    alert('발송되었습니다')

    navigate(0)

  }

  const testPush = async (push) => {

    const checkImage = (name) => {

      const afterImage = document.querySelector('#' + name).getAttribute('src')

      return push[name] === afterImage ? push[name] : afterImage
    }

    const data = {
      ...push,
      emails: push.emails === '' ? [] : push.emails.split(';').map(e => e.replace(/^\s+|\s+$/g, '')).filter(e => e != ''),
      imageUrl: checkImage('imageUrl')
    }

    await axios.post(`${HOST_URL}/app/sendPush`, {
      lang: 'ko',
      token: localStorage.getItem('admin_token'),
      ...data
    })

    setOpendNewPush(false)
    navigate(0)

  }

  const addPush = async (push) => {

    const checkImage = (name) => {

      const afterImage = document.querySelector('#' + name).getAttribute('src')

      return push[name] === afterImage ? push[name] : afterImage
    }

    const data = {
      ...push,
      email_group: push.email_group === '' ? [] : push.email_group.split(';').map(e => e.replace(/^\s+|\s+$/g, '')).filter(e => e != ''),
      img_url: checkImage('img_url')
    }

    await axios.post(`${HOST_URL}/app/appAdminPushRegist`, {
      lang: 'ko',
      token: localStorage.getItem('admin_token'),
      ...data
    })

    setOpendNewPush(false)
    navigate(0)

  }

  const editPush = async (push) => {

    const checkImage = (name) => {

      const afterImage = document.querySelector('#' + name).getAttribute('src')

      return push[name] === afterImage ? push[name] : afterImage
    }    

    const data = {
      ...push,
      img_url: checkImage('img_url')
    }

    await axios.post(`${HOST_URL}/app/appAdminPushUpdate`, {
      lang: 'ko',
      push_seq: item.push_seq,
      token: localStorage.getItem('admin_token'),
      ...data
    })

    setOpendEditPush(false)
    navigate(0)
    
  }

  return (
    <ContentLayout 
      title='푸시 관리'
      description='푸시 관리 페이지입니다'
      buttons={[
        { label: '시험발송', onClick: () => setOpendTestPush(true)},
        { label: '발송', onClick: () => sendPushMessage()},
        { label: '등록', onClick: () => setOpendNewPush(true)}   
      ]} 
    >

      <Table
        apiUrl={`${HOST_URL}/app/appAdminPushList`}
        listName='pushes'
        countName='totalCount'
        columns={[
          { name: '푸시 번호', selector: row => row.push_seq },
          { name: '제목', selector: row => row.subject },
          { name: '내용', selector: row => row.content },
          { name: '전송여부', selector: row => row.send_yn },
          { name: '푸시구분', selector: row => row.push_type },
          { name: '순번', selector: row => row.seq },
          { name: '이메일', selector: row => row.email_addr },
          { name: '등록일', selector: row => convertDatetime(row.crt_dt) },
        ]}
        searchs={[        
          { label: '이메일', value: 'email_addr' },
          { label: '제목', value: 'subject' },
          { label: '내용', value: 'content' },
          { label: '전송여부', value: 'send_yn', options: [
            { label: 'N', value: 'N' },
            { label: 'Y', value: 'Y' }
          ] }          
        ]}
        onClickRow={(row) => {
            setItem(row)
            setOpendEditPush(true)
        }}        
        selectableRows={false}
      />
      <Modal
        isOpen={opendTestPush}
        onClose={() => setOpendTestPush(false)}
      >
        <ModalContent 
          title='푸시 시험발송' 
          description='푸시 시험발송 페이지입니다'
          data={item}
          columns={ [
            { label: '이메일', name: 'emails', placeholder: 'ex: aa@gc.m;bb@gc.m; 세미클론 구분 (비어있을 시 전체 등록)', type: 'text', editable: true },            
            { label: '제목', name: 'title', type: 'text', editable: true },
            { label: '내용', name: 'body', type: 'textarea', editable: true },
            { label: '이미지', name: 'imageUrl', type: 'image', apiUrl: '/app/upload/push', editable: true },
            { label: '푸시구분', name: 'push_type', type: 'select', selectOptions: [
              { label: '공지사항', value: 'noti' },
              { label: 'Q&A', value: 'qna' },
              { label: '광고', value: 'ad' },
              { label: '이벤트', value: 'event' }
            ], editable: true 
          },
          { label: '순번', name: 'seq', type: 'number', editable: true },
          ]}
          onSubmit={testPush}
          onClose={() => setOpendTestPush(false)}
          submitLabel='발송'
        />        
      </Modal>
      
      <Modal
        isOpen={opendNewPush}
        onClose={() => setOpendNewPush(false)}
      >
        <ModalContent 
          title='푸시 등록' 
          description='푸시 등록 페이지입니다'
          data={item}
          columns={ [
            { label: '제목', name: 'subject', type: 'text', editable: true },
            { label: '내용', name: 'content', type: 'textarea', editable: true },
            { label: '이미지', name: 'img_url', type: 'image', apiUrl: '/app/upload/push', editable: true },
            { label: '푸시구분', name: 'push_type', type: 'select', selectOptions: [
              { label: '공지사항', value: 'noti' },
              { label: 'Q&A', value: 'qna' },
              { label: '광고', value: 'ad' },
              { label: '이벤트', value: 'event' }
            ], editable: true 
          },
          { label: '순번', name: 'seq', type: 'number', editable: true },
          { label: '이메일', name: 'email_group', placeholder: 'ex: aa@gc.m;bb@gc.m; 세미클론 구분 (비어있을 시 전체 등록)', type: 'text', editable: true },
          ]}
          onSubmit={addPush}
          onClose={() => setOpendNewPush(false)}
          submitLabel='등록'
        />        
      </Modal>

      <Modal
        isOpen={opendEditPush}
        onClose={() => {
          setOpendEditPush(false)
          setItem({})
        }}
      >
        <ModalContent 
          title='푸시 수정' 
          description='푸시 수정 페이지입니다'
          data={item}
          columns={ [
            { label: '푸시 번호', name: 'push_seq', type: 'text' },
            { label: '제목', name: 'subject', type: 'text', editable: true },
            { label: '내용', name: 'content', type: 'textarea', editable: true },
            { label: '이미지', name: 'img_url', type: 'image', apiUrl: '/app/upload/push', editable: true },
            { label: '전송여부', name: 'send_yn', type: 'select', selectOptions: [
              { label: 'N', value: 'N' },
              { label: 'Y', value: 'Y' },
              ], editable: true 
            },
            { label: '푸시구분', name: 'push_type', type: 'select', selectOptions: [
              { label: 'noti', value: 'noti' },
              { label: 'qna', value: 'qna' },
              { label: 'ad', value: 'ad' },
              { label: 'event', value: 'event' }
            ], editable: true 
          },
            { label: '순번', name: 'seq', type: 'number', editable: true },
            { label: '이메일', name: 'email_addr', type: 'text' }
          ]}
          onSubmit={editPush}
          onClose={() => {
            setOpendEditPush(false)
            setItem({}) 
          }}
          submitLabel='수정'
        />        
      </Modal>
    </ContentLayout>
  );
}
