import React, { useCallback, useEffect, useState } from 'react';
import { ContentLayout } from '../shared/content-layout';
import { FormSubmitButton, FormLabel, FormInput, FormImg, FormImgs, FormSelect, FormTextarea, FormButton, FormKeywords, ButtonGroup, FormSmallInput, FormRadioLabels, FormSliderBox, FormFileUploadBox, FormPendingAlert, FormSuccessAlert, FormLabelNotice, FormSortableImgs, FormDeleteImgIcon, FormLabelError } from '../shared/styled'
import { useFieldArray, useForm } from 'react-hook-form'
import Slider from 'rc-slider';
import axios from 'axios'
import readXlsxFile from 'read-excel-file'
import { HOST_URL } from '../shared/env'
import { FormDropDownBox } from '../shared/FormDropDownBox';
import 'rc-slider/assets/index.css';

export function AdNewStore() {

  const { control, register, handleSubmit, setValue, watch, formState: { errors } } = useForm()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ads',
  })

  const [thumbnails, setThumbnails] = useState([])
  const [images, setImages] = useState([])
  const [keywords, setKeywords] = useState([])  

  const [cmpy_codes, setCmpyCodes] = useState([])  
  const [discnt_codes, setDiscntCodes] = useState([])

  const [submited, setSubmited] = useState(false)  

  const [ads, setAds] = useState([])
  const watchAds = watch('ads')  

  const onSubmit = async (data) => {

    if (submited) return

    for (let i = 0; i < fields.length; i++) setValue(`ads.${i}.opend`, false)    

    setSubmited(true)
  
    for (let i = 0; i < data.ads.length; i++) {

      const e = data.ads[i]

      const payload = {
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        ad_seq: 0,
        ad_type: 'D0001',
        ad_subj: e.ad_subj,
        ad_cont: e.ad_cont,
        ad_keyword: keywords.find(x => x.no === e.no).tags.map(e => e.text).join(','),
        cmpy_type: e.cmpy_type,
        postal_cd: e.postal_cd,
        addr1: e.addr1,
        addr2: e.addr2,
        latitude: e.latitude,
        logitude: e.logitude,
        area_type: e.area_type,
        ...(
          e.area_type === '2' ?
            {
              ad_dist: e.ad_dist
            }
            :
            {

            }
        ),
        discnt_prc: e.discnt_prc,
        discnt_type: e.discnt_type,
        prod_link: e.prod_link,
        perd_type: e.perd_type,
        ...(
          e.perd_type === '1' ?
          {
            
            exp_perd: e.exp_perd
          }
          :
          (
            e.perd_type === '2' ?
            {
              ad_begin: e.ad_begin,
              ad_end: e.ad_end,
            }
            :
            {
              ad_begin: e.ad_begin,
            }
          )
        ),
        budget_type: e.budget_type,
        budget: e.budget,
        exp_perd: e.exp_perd ? 60 : 30,
        ad_range : [ 
          {
            postal_cd: e.postal_cd,
            addr1: e.addr1,
            latitude: e.latitude,
            logitude: e.logitude
          }
        ]
      }

      if (e.done) continue;

      const vaildKeys = [
        'ad_subj',
        'ad_cont',
        'cmpy_type',
        'area_type',
        'postal_cd',
        'addr1',
        'latitude',
        'logitude',
        'perd_type',
        'budget_type',
        'budget'
      ]

      if (e.perd_type != '3') vaildKeys.push(...(e.perd_type === '1' ? ['exp_perd'] : ['ad_begin', 'ad_end']))

      const unvaild = vaildKeys.filter(x => e[`${x}`] === '')

      if (!thumbnails.find(x => x.no === e.no).value) unvaild.push('ad_img_rep')
      if (!keywords.find(x => x.no === e.no).tags.length) unvaild.push('ad_keyword')      

      setValue(`ads.${i}.failed`, !!unvaild.length)

      if (unvaild.length) {
        setValue(`ads.${i}.failed_text`, `[${unvaild.join(', ')}]가 누락되어 있습니다`)
        continue;
      }

      const thumbnail = thumbnails.find(x => x.no === e.no)
      const thumbnailFormData = new FormData()
      thumbnailFormData.append('files', thumbnail.file)

      const thumbnailResp = await axios({
        method: 'post',
          url: `${HOST_URL}/app/upload`,
        data: thumbnailFormData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      const ad_img_rep = thumbnailResp.data.result[0]
      const ad_imgs = {}

      const _images = images.find(x => x.no === e.no)

      for (let x = 0; x < _images.values.length; x++) {
        const image = _images.values[x]

        const formData = new FormData()
        formData.append('files', image.file)
  
        const imageResp = await axios({
          method: 'post',
          url: `${HOST_URL}/app/upload`,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        ad_imgs[`ad_img${x + 1}`] = imageResp.data.result[0]
        
      }

      await axios.post(`${HOST_URL}/app/appBizAdRegist`, {
        ...payload,
        ...ad_imgs,
        ad_img_rep,
      })

      setValue(`ads.${i}.done`, true)
 
    }

    setSubmited(false)    

  }

  const onSearchAddress = useCallback((i) => {

    new window.daum.Postcode({
      oncomplete: async (data) => {
        setValue(`ads.${i}.addr1`, data.address)
        setValue(`ads.${i}.postal_cd`, data.zonecode)

        const resp = await axios.get(`https://dapi.kakao.com/v2/local/search/address.json?query=${data.address}`, {
          headers: {
            'Authorization': `KakaoAK 9cf7b96b4949250f8974ba4c9d8e575c`
          }
        })

        const { data: { documents } } = resp

        setValue(`ads.${i}.latitude`, documents[0].y)
        setValue(`ads.${i}.logitude`, documents[0].x)

      }
    }).open()

  }, [window.daum])

  useEffect(() => {

    if (watchAds) setAds(watchAds)

  }, [watchAds])

  useEffect(() => {

    (async () => {

      const respCmpyCodes = await axios.post(`${HOST_URL}/app/appCodeList`, {
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        mst_cd_id: 'M0002'
      })
  
      setCmpyCodes(respCmpyCodes.data.result)      

      const respDiscntCodes = await axios.post(`${HOST_URL}/app/appCodeList`, {
        lang: 'ko',
        token: localStorage.getItem('admin_token'),
        mst_cd_id: 'M0004'
      })
  
      setDiscntCodes(respDiscntCodes.data.result)   

    })()


  }, [])  

  return (
    <ContentLayout 
      title='내 가게 광고 등록' 
      description='내 가게 광고 등록 페이지입니다'
    >

      <ButtonGroup>
        <div>
          <label htmlFor='upload-excel'>엑셀업로드</label>
          <input type='file' id='upload-excel' style={{display: 'none'}} onChange={(e) => {

            readXlsxFile(e.target.files[0]).then( async (rows) => {

              const keys = rows[0].map((e, i) => ({
                index: i,
                name: e.split('(')[1].replace(')', '')
              }))

              const _rows = rows.slice(1)

              for (let i = 0; i < _rows.length; i++) {

                const e = _rows[i];

                const data = e.reduce((acc, cur, i) => {
                  
                  if (cur) acc[[keys.find(e => e.index === i).name]] = cur

                  return acc
                }, { })

                let file = undefined,
                    blob = undefined

                if (data.ad_img_rep) {

                  const resp = await axios.get(data.ad_img_rep, { responseType: 'blob' })

                  file = new File([resp.data], 'exampleimage')
                  blob = URL.createObjectURL(file)

                }

                const adImages = []

                const ad_imgs = ['ad_img1', 'ad_img2', 'ad_img3', 'ad_img4', 'ad_img5', 'ad_img6', 'ad_img7', 'ad_img8', 'ad_img9', 'ad_img10']

                for (let x = 0; x < ad_imgs.length; x++) {

                  const img = data[`${ad_imgs[x]}`]

                  if (img) {

                    const resp = await axios.get(img, { responseType: 'blob' })
                    let file = new File([resp.data], 'exampleimage')
                    let blob = URL.createObjectURL(file)

                    adImages.push({
                      value : blob,
                      file
                    })

                  }

                }

                const no = Math.random().toString().slice(5)

                append({
                  no,
                  opend: false,
                  area_type: '2',
                  perd_type: '1',
                  budget_type: '1',                  
                  ...data
                })
                setThumbnails(e => [...e, { no, value: blob, file }])
                setImages(e => [...e, { no, values: [...adImages] }])
                setKeywords(e => [...e, { no, tags: [] }])

              }

            })

            e.target.value = ''

          }} />
        </div>
        <div onClick={() => {
          const no = new Date().getTime()
          append({
            no,
            opend: false,
            area_type: '2',
            perd_type: '1',
            budget_type: '1'
          })
          setThumbnails(e => [...e, { no, value: '' }])
          setImages(e => [...e, { no, values: [] }])
          setKeywords(e => [...e, { no, tags: [] }])
        }}>등록 폼 추가</div>
      </ButtonGroup>   

      <form onSubmit={handleSubmit(onSubmit)}>

      {fields.map((field, i) => (
        <FormDropDownBox opend={watch(`ads.${i}.opend`)} doned={watch(`ads.${i}.done`)} key={i} text={watch(`ads.${i}.failed`) ? <FormLabelNotice>{watch(`ads.${i}.failed_text`)}</FormLabelNotice> : (watch(`ads.${i}.ad_subj`) || '제목을 입력해주세요')} onDelete={() => remove(i)} onOpend={() => setValue(`ads.${i}.opend`, !watch(`ads.${i}.opend`))}>
          <div>
            <FormLabel>대표사진 <small>(ad_img_rep) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <FormFileUploadBox
              htmlFor={`upload-thumbnail_${i}`}
              onDragOver={(e) => { 
                e.preventDefault()
                e.stopPropagation()
              }}
              onDrop={(e) => {
                if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                  setThumbnails(x => x.map(y => {

                    if (y.no === field.no) {
                      y.value = URL.createObjectURL(e.dataTransfer.files[0])
                      y.file = e.dataTransfer.files[0]
                    }

                    return y
                  }))
                }
                e.preventDefault()
              }}
            >
            <div>
              <input id={`upload-thumbnail_${i}`} type="file" style={{display: 'none'}} onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setThumbnails(x => x.map(y => {

                    if (y.no === field.no) {
                      y.value = URL.createObjectURL(e.target.files[0])
                      y.file = e.target.files[0]
                    }

                    return y
                  }))
                }
              }} />
            </div>
              {thumbnails.find(e => e.no == field.no).value && <FormImg style={{ marginTop: 0, backgroundImage: `url(${thumbnails.find(e => e.no == field.no).value})`}} /> }
            </FormFileUploadBox>
          </div>
          <div>
            <FormLabel>상세사진 <small>(ad_img)</small>
            </FormLabel>
            <div>
                <FormImgs>
                  <FormSortableImgs list={images.find(e => e.no == field.no).values} setList={(newState) => { 
                    setImages(images.map(y => {
                        y.values = newState
                        return y
                      }))
                    }}>
                    {images.find(e => e.no == field.no).values.map((item, i) => (
                      <FormImg key={item.value} style={{ backgroundImage: `url(${item.value})`}}>
                      <FormDeleteImgIcon alt='xmark' src='/images/xmark-solid.svg' onClick={() => {
                        setImages(images.map((y) => {
                            if (y.no === field.no) {

                              y.values = y.values.filter((_, idx) => idx !== i)

                            }
                          return y
                        }))
                      }} />
                    </FormImg>
                    ))}
                  </FormSortableImgs>                  
                  <FormFileUploadBox
                    htmlFor={`upload-images_${i}`}
                    onDragOver={(e) => { 
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onDrop={(e) => {
                      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                        setImages(images.map(y => {
                          if (y.no === field.no) y.values = [...y.values, ...Array.from(e.dataTransfer.files).map(e => ({
                            value: URL.createObjectURL(e),
                            file: e
                          }))]

                          return y
                        }))
                      }
                      e.target.value = ''
                      e.preventDefault()
                    }}
                  >
                    <div>
                      <input id={`upload-images_${i}`} type="file" style={{display: 'none'}} multiple onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          setImages(images.map(y => {
                            if (y.no === field.no) y.values = [...y.values, ...Array.from(e.target.files).map(e => ({
                              value: URL.createObjectURL(e),
                              file: e
                            }))] 

                            return y
                          }))
                        }
                        e.target.value = ''
                      }} />
                    </div>
                  </FormFileUploadBox>
                </FormImgs>
            </div>
          </div>
          <div>
            <FormLabel>제목 <small>(ad_subj) <FormLabelNotice>*필수</FormLabelNotice> {(errors.ads && errors.ads[i] && errors.ads[i].ad_subj) && <FormLabelError>제목은 100자 이하로 적어주세요</FormLabelError>}</small>
            </FormLabel>
            <div>
              <FormInput placeholder='5자 이상 50자 이내로 입력해주세요' { ...register(`ads.${i}.ad_subj`, { maxLength: 100 }) } type="text" />
            </div>
          </div>
          <div>
            <FormLabel>내용 <small>(ad_cont) <FormLabelNotice>*필수</FormLabelNotice> {(errors.ads && errors.ads[i] && errors.ads[i].ad_cont) && <FormLabelError>내용은 2000자 이하로 적어주세요</FormLabelError>}</small>
            </FormLabel>
            <div>
              <FormTextarea placeholder='내용을 5자 이상 2000자 이내로 입력해주세요' { ...register(`ads.${i}.ad_cont`, { maxLength: 2000 }) } type="text"></FormTextarea>
            </div>
          </div>
          <div>
            <FormLabel>키워드 <small>(ad_keyword) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <div>
              <FormKeywords
                classNames={{
                  tagInputField: 'form-keyword-input',
                  selected: 'form-keyword-selected-box',
                  tag: 'form-keyword',
                  remove: 'form-keyword-remove-icon'
                }}
                tags={keywords.find(e => e.no == field.no).tags}
                delimiters={[188, 13]}
                handleDelete={(idx) => {
                  setKeywords(keywords => {

                    return keywords.map(e => {

                      if (e.no === field.no) {
                        const k = e.tags.filter((tag, index) => index !== idx)
                        e.tags = k
                      }

                      return e
                    })
                  })
                }}
                handleAddition={(tag) => {
                  setKeywords(keywords => {
                    return keywords.map(e => {

                      if (e.no === field.no) {
                        const k = [...e.tags, tag]
                        e.tags = k
                      }

                      return e
                    })
                  })
                }}
                inputFieldPosition='top'
                autocomplete={true}
                autofocus={false}
                placeholder='Enter를 누르시면 키워드가 추가됩니다'
              />
            </div>
          </div>          
          <div>
            <FormLabel>업종 <small>(cmpy_type) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <div>
              <FormSelect { ...register(`ads.${i}.cmpy_type`) }>
                <option value=''>===============</option>
                {cmpy_codes.map((e, i) => <option key={i} value={e.dtl_cd_id}>{e.dtl_cd_nm}</option>)}
              </FormSelect>
            </div>
          </div>
          <div>
            <div>
              <FormButton onClick={() => onSearchAddress(i)}>주소찾기</FormButton>
            </div>          
            <div>
              {/* <FormLabel>우편번호 <small>(postal_cd) <FormLabelNotice>*필수</FormLabelNotice></small>
              </FormLabel> */}
              <div>
                <FormSmallInput { ...register(`ads.${i}.postal_cd`) } type="hidden" />
              </div>
            </div>
            <div>
              <FormLabel>매장위치 <small>(addr1) <FormLabelNotice>*필수</FormLabelNotice></small>
              </FormLabel>
              <div>
                <FormInput placeholder='주소찾기를 통해 입력하세요' { ...register(`ads.${i}.addr1`) } type="text" />
              </div>
            </div>
            <div>
              <FormLabel>매장 주소 상세 <small>(addr2) <FormLabelNotice>*필수</FormLabelNotice></small> {(errors.ads && errors.ads[i] && errors.ads[i].addr2) && <FormLabelError>상세주소는 100자 이자로 적어주세요</FormLabelError>}
              </FormLabel>
              <div>
                <FormInput placeholder='상세 주소를 입력하세요' { ...register(`ads.${i}.addr2`, { maxLength: 100 }) } type="text" />
              </div>
            </div>
            <div>
              {/* <FormLabel>위도 <small>(latitude) <FormLabelNotice>*필수</FormLabelNotice></small>
              </FormLabel> */}
              <div>
                <FormSmallInput { ...register(`ads.${i}.latitude`) } type="hidden" />
              </div>
            </div>
            <div>
              {/* <FormLabel>경도 <small>(logitude) <FormLabelNotice>*필수</FormLabelNotice></small>
              </FormLabel> */}
              <div>
                <FormSmallInput { ...register(`ads.${i}.logitude`) } type="hidden" />
              </div>
            </div>
          </div>          
          <div>
            <FormLabel>지역선택구분 <small>(area_type) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <FormRadioLabels>
              <label>
                <FormSmallInput defaultChecked={true} { ...register(`ads.${i}.area_type`) } value={2} type="radio" />
                주변 범위(영업점 기준)
              </label>
              <label>
                <FormSmallInput { ...register(`ads.${i}.area_type`) } value={3} type="radio" />
                지역 선택 안 함
              </label>
            </FormRadioLabels>
          </div>

          {watch(`ads.${i}.area_type`) === '2' && 
          <div>
            <FormLabel>반경거리(미터) <small>(ad_dist) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <div>
              <FormSmallInput placeholder='숫자만 입력하세요' { ...register(`ads.${i}.ad_dist`) } type="number" />
            </div>
            <div>
              <FormSliderBox>
                <Slider 
                  value={watch(`ads.${i}.ad_dist`)}
                  min={0}
                  step={[100,300,500,1000, 3000]}
                  max={3000} 
                  marks={{
                    100: '100m',
                    300: '300m',
                    500: '500m',
                    1000: '1km',
                    3000: '3km'
                  }}
                  onChange={(e) => {
                    setValue(`ads.${i}.ad_dist`, e)
                  }}
                />
              </FormSliderBox>
            </div>
          </div>
          }          
          <div>
            <FormLabel>할인가격 <small>(discnt_prc)</small>
            </FormLabel>
            <div>
              <FormSmallInput placeholder='숫자만 입력하세요' { ...register(`ads.${i}.discnt_prc`) } type="number" />
            </div>
          </div>
          <div>
            <FormLabel>할인행사종류 <small>(discnt_type)</small>
            </FormLabel>
            <div>
              <FormSelect { ...register(`ads.${i}.discnt_type`) }>
                <option value=''>===============</option>
                {discnt_codes.map((e, i) => <option key={i} value={e.dtl_cd_id}>{e.dtl_cd_nm}</option>)}
              </FormSelect>              
            </div>
          </div>
          <div>
            <FormLabel>링크 <small>(prod_link)</small> {(errors.ads && errors.ads[i] && errors.ads[i].prod_link) && <FormLabelError>링크는 500자 이하로 적어주세요</FormLabelError>}
            </FormLabel>
            <div>
              <FormInput placeholder='url을 붙여넣어 주세요' { ...register(`ads.${i}.prod_link`, { maxLength: 500 }) } type="text" />
            </div>
          </div>
          <div>
            <FormLabel>기간구분 <small>(perd_type) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <div>
              <FormRadioLabels>
              <label>
                <FormSmallInput { ...register(`ads.${i}.perd_type`) } value={1} type="radio" />
                지금시작
              </label>
              <label>
                <FormSmallInput { ...register(`ads.${i}.perd_type`) } value={2} type="radio" />
                예약
              </label>
              <label>
                <FormSmallInput { ...register(`ads.${i}.perd_type`) } value={3} type="radio" />
                종료일 없음
              </label>
            </FormRadioLabels>              
            </div>
          </div>
          {watch(`ads.${i}.perd_type`) === '1' && 
          <div>
            <FormLabel>광고진행기간<small>(exp_perd) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <div>
              <FormSmallInput placeholder='숫자만 입력하세요' { ...register(`ads.${i}.exp_perd`) } type="number" />
            </div>
          </div>
          }
          {(watch(`ads.${i}.perd_type`) === '2' || watch(`ads.${i}.perd_type`) === '3') &&         
          <div>
            <FormLabel>광고시작일시 <small>(ad_begin) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <div>
              <FormSmallInput { ...register(`ads.${i}.ad_begin`) } type="datetime-local" />
            </div>
          </div>
          }
          {watch(`ads.${i}.perd_type`) === '2' && 
          <div>
            <FormLabel>광고종료일시 <small>(ad_end) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <div>
              <FormSmallInput { ...register(`ads.${i}.ad_end`) } type="datetime-local" />
            </div>
          </div>
          }
          <div>
            <FormLabel>일예산구분 <small>(budget_type) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <div>
              <FormRadioLabels>
                <label>
                  <FormSmallInput { ...register(`ads.${i}.budget_type`) } value={1} type="radio" />
                  직접입력
                </label>
                <label>
                  <FormSmallInput { ...register(`ads.${i}.budget_type`) } value={2} type="radio" />
                  추천예산
                </label>
              </FormRadioLabels>
            </div>
          </div>
          {watch(`ads.${i}.budget_type`) === '1' ?
          <div>
            <FormLabel>일 예산 <small>(budget) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <div>
              <FormSmallInput placeholder='숫자만 입력하세요' { ...register(`ads.${i}.budget`) } type="number" />
            </div>
          </div>
          :
          <div>
            <FormLabel>추천 예산<small>(budget) <FormLabelNotice>*필수</FormLabelNotice></small>
            </FormLabel>
            <div>
              <FormRadioLabels>                
                <label>
                  <FormSmallInput { ...register(`ads.${i}.budget`) } value={15000} type="radio" />
                  15,000원
                </label>                
                <label>
                  <FormSmallInput { ...register(`ads.${i}.budget`) } value={25000} type="radio" />
                  25,000원
                </label>
                <label>
                  <FormSmallInput { ...register(`ads.${i}.budget`) } value={35000} type="radio" />
                  35,000원
                </label>                
              </FormRadioLabels>
            </div>
          </div>
          }      
        </FormDropDownBox>
        ))}

        <div style={{height: '25px'}}>
          {ads.filter(e => e.failed).length === 0 ?
            <div>
              {ads.filter(e => !e.done).length ?
              <FormPendingAlert>
                🚀 현재 {ads.length}개의 광고를 작성중입니다... ({ads.filter(e => e.done).length}/{ads.length})
              </FormPendingAlert>
              :
              !!ads.length && <FormSuccessAlert>🎉 모든 광고가 성공적으로 등록되었습니다.</FormSuccessAlert>
              }
            </div>
          :
          <FormSuccessAlert>
            🎉 {ads.length}개의 광고중 {ads.filter(e => e.done).length}개가 성공적으로 등록되었습니다. ({ads.filter(e => e.done).length}/{ads.length})
          </FormSuccessAlert>
          }
        </div>

        <FormSubmitButton type='submit' value='등록' />

      </form>

      <ButtonGroup>
        <FormButton type="submit" onClick={() => window.open('/download/new-store-ad.xlsx')}>내 가게 광고 엑셀 양식 받기</FormButton>
      </ButtonGroup>
      
    </ContentLayout>
  );
}
