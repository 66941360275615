import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, Link as DomLink, useLocation, useNavigate } from 'react-router-dom';
import { Sidebar as ReactSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import styled from '@emotion/styled';
import axios from 'axios';
import { HOST_URL } from '../shared/env';

export function MainLayout() {

  const [token, setToken] = useState()
  const [name, setName] = useState('')
  const [toggled, setToggled] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const [pathname, setPathname] = useState('')

  const canActiveNav = useCallback((name) => {

    return name === pathname

  }, [pathname])

  const signOut = () => {
    localStorage.setItem('admin_token', '')
    navigate('/login')
  }

  useEffect(() => {

    setPathname(location.pathname.replace('/', ''))
    setToggled(false)

  }, [location]);

  useEffect(() => {

    if (token) {

      (async () => {
        const { data : { result } } = await axios.post(`${HOST_URL}/app/appUserInfo`, {
          lang: 'ko',
          token
        })

        setName(result.user[0].user_nick_nm)
      })()

    }

  }, [token])

  useEffect(() => {

    setToken(localStorage.getItem('admin_token'))

    return () => {
      console.log('Main destroy.');
    };
  }, []);

  return (
    <Wrapper>
      <Header>
        <HeaderContent>
          <SidebarButton alt='bars' src='/images/bars-solid.svg' onClick={() => setToggled(!toggled)} />
        </HeaderContent>
        <HeaderContent>
          <LogoBox>
            <Link to='/'>
              <img alt='logo' src='/images/logo.png' />
            </Link>
          </LogoBox>
        </HeaderContent>
        <HeaderContent>
          <InfoBox>
            <InfoProfile>{name}님</InfoProfile>
            <SignOutButton onClick={signOut}><img alt='logout' src='/images/right-from-bracket-solid.svg' /></SignOutButton>
          </InfoBox>
        </HeaderContent>
      </Header>
      <Container>
        <Sidebar toggled={toggled} onBackdropClick={() => setToggled(false)} breakPoint='lg' backgroundColor='#fff'>
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => ({
                backgroundColor: active ? '#FFCE3E' : undefined
              })
            }}
          >
            <SubMenu label={<SubMenuLabel>회원관리</SubMenuLabel>} defaultOpen={false}>
              <Link to='/user-mgt'><MenuItem active={ canActiveNav('user-mgt') }>회원관리</MenuItem></Link>
              <Link to='/user-grade'><MenuItem active={ canActiveNav('user-grade') }>회원 등급 부여</MenuItem></Link>
              <Link to='/user-total'><MenuItem active={ canActiveNav('user-total') }>신규/탈퇴회원 집계</MenuItem></Link>
            </SubMenu>
            <SubMenu label={<SubMenuLabel>광고관리</SubMenuLabel>} defaultOpen={false}>
                <SubMenu label={<SubMenuLabel>광고등록</SubMenuLabel>} defaultOpen={false}>
                  <Link to='/ad-new/store'><MenuItem active={ canActiveNav('ad-new/store') }>내 가게 광고 등록</MenuItem></Link>
                  <Link to='/ad-new/coupon'><MenuItem active={ canActiveNav('ad-new/coupon') }>할인 쿠폰</MenuItem></Link>
                  <Link to='/ad-new/online'><MenuItem active={ canActiveNav('ad-new/online') }>온라인 할인 행사</MenuItem></Link>
                  <Link to='/ad-new/offline'><MenuItem active={ canActiveNav('ad-new/offline') }>오프라인 할인 행사</MenuItem></Link>
                </SubMenu>
              <Link to='/ad-confirm'><MenuItem active={ canActiveNav('ad-confirm') }>광고승인/반려</MenuItem></Link>
              <Link to='/ad-modify'><MenuItem active={ canActiveNav('ad-modify') }>광고수정</MenuItem></Link>
              <Link to='/ad-report'><MenuItem active={ canActiveNav('ad-report') }>신고관리</MenuItem></Link>
              <Link to='/ad-total'><MenuItem active={ canActiveNav('ad-total') }>광고 실적 집계</MenuItem></Link>
            </SubMenu>
            <SubMenu label={<SubMenuLabel>로또관리</SubMenuLabel>} defaultOpen={false}>
              <Link to='/lotto-create'><MenuItem active={ canActiveNav('lotto-create') }>로또등록</MenuItem></Link>
              <Link to='/lotto-payment'><MenuItem active={ canActiveNav('lotto-payment') }>로또결과반영</MenuItem></Link>
            </SubMenu>
            <SubMenu label={<SubMenuLabel>코인관리</SubMenuLabel>} defaultOpen={false}>
              <Link to='/coin-mgt'><MenuItem active={ canActiveNav('coin-mgt') }>코인 관리</MenuItem></Link>
              <Link to='/coin-total'><MenuItem active={ canActiveNav('coin-total') }>코인 집계</MenuItem></Link>
            </SubMenu>
            <SubMenu label={<SubMenuLabel>포인트관리</SubMenuLabel>} defaultOpen={false}>
              <Link to='/point-withdrawal'><MenuItem active={ canActiveNav('point-withdrawal') }>포인트 출금 관리</MenuItem></Link>
              <Link to='/point-mgt'><MenuItem active={ canActiveNav('point-mgt') }>포인트 관리</MenuItem></Link>
              <Link to='/point-total'><MenuItem active={ canActiveNav('point-total') }>포인트 집계</MenuItem></Link>
            </SubMenu>
            <SubMenu label={<SubMenuLabel>광고예산관리</SubMenuLabel>} defaultOpen={false}>
              <Link to='/budget-mgt'><MenuItem active={ canActiveNav('budget-mgt') }>결제 관리</MenuItem></Link>
              <Link to='/budget-reject'><MenuItem active={ canActiveNav('budget-reject') }>결제 취소</MenuItem></Link>
              <Link to='/budget-total'><MenuItem active={ canActiveNav('budget-total') }>결제 집계</MenuItem></Link>     
            </SubMenu>
            <SubMenu label={<SubMenuLabel>한줄평관리</SubMenuLabel>} defaultOpen={false}>
              <Link to='/comment-mgt'><MenuItem active={ canActiveNav('comment-mgt') }>한줄평관리</MenuItem></Link>
            </SubMenu>
            <SubMenu  label={<SubMenuLabel>게시판관리</SubMenuLabel>} defaultOpen={false}>
              <Link to='/board-notice'><MenuItem active={ canActiveNav('board-notice') }>공지사항</MenuItem></Link>
              <Link to='/board-faq'><MenuItem active={ canActiveNav('board-faq') }>자주묻는 질문</MenuItem></Link>
              <Link to='/board-qna'><MenuItem active={ canActiveNav('board-qna') }>문의사항</MenuItem></Link>
            </SubMenu>
            <SubMenu  label={<SubMenuLabel>푸시관리</SubMenuLabel>} defaultOpen={false}>
              <Link to='/push-mgt'><MenuItem active={ canActiveNav('push-mgt') }>푸시관리</MenuItem></Link>
            </SubMenu>
            <Link to='/fr-test'>
              <MenuItem>얼굴 인식 테스트</MenuItem>
            </Link>
            <Link to='/document-ocr-test'>
              <MenuItem>OCR 테스트(Document OCR)</MenuItem>
            </Link>
            <Link to='/ocr-test'>
              <MenuItem>OCR 테스트(Basic)</MenuItem>
            </Link>            
            <Link to='/bank-ocr-test'>
              <MenuItem>통장 OCR 테스트</MenuItem>
            </Link>            
          </Menu>
        </Sidebar>
        <Content>
          <Outlet />
        </Content>
      </Container>
      <Footer>
        @DCwow Admin
      </Footer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
display: flex;
flex-direction: column;
min-height: 100vh;
`

const Header = styled.header`
background: #fff;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 16px;
`

const HeaderContent = styled.div`
display: flex;
width: 33.3%;
`

const Container = styled.div`
flex: 1;
display: flex;
`

const Content = styled.div`
flex: 1;
width: 50%;
`

const Footer = styled.div`
padding: 10px;
text-align: center;
`

const LogoBox = styled.div`
width: 100%;
text-align: center;

img {
  width: 120px;
  cursor: pointer;
}
`

const SidebarButton = styled.img`
width: 24px;
cursor: pointer;
`

const InfoBox = styled.div`
display: flex;
align-items: center;
text-align: right;
margin-left: auto;
`

const InfoProfile = styled.div`
font-size: 16px;
`

const SignOutButton = styled.div`
padding-left: 6px;
cursor: pointer;

img {
  width: 24px;
}
`

const Link = styled(DomLink)`
color: #000;
text-decoration: none;
`

const Sidebar = styled(ReactSidebar)`
background-color: #fff;
`

const SubMenuLabel = styled.div`

img {
  width: 16px;
}
`