import dayjs from 'dayjs'

export const convertDatetime =  (date, type) => {

    if (!date) return ''

    date = dayjs(date.replace('T', ' ').replace('Z', ''))

    switch(type) {

        case 0 : return date.format('YYYY년 MM월 DD일')
        case 1 : return date.format('YYYY년 MM월 DD일 HH시')
        default: return date.format('YYYY년 MM월 DD일 HH시 mm분')

    }

}