import styled from "@emotion/styled"
import { Table } from "./Table"
import { ContentLayout } from "./content-layout"
import { useEffect } from "react"

export function ModalTable({ title, description, buttons, data, columns, onClose, onSubmit, submitLabel = '확인', apiType, apiUrl, listName, countName, selectableRows, paginationServer }) {

  return (<Container>
    <ContentLayout 
      title={title}
      description={description}
      buttons={buttons}
    >
      <Table
        apiUrl={apiUrl}
        apiType={apiType}
        apiSeq={data.ad_seq}
        listName={listName}
        countName={countName}
        columns={columns}
        selectableRows={selectableRows}
        paginationServer={paginationServer}
      />
    </ContentLayout>

    <FloatingButtonGroup>
      {
        onSubmit ? <>
          <ButtonItem type='button' value={'닫기'} onClick={onClose} />
          <ButtonItem type='submit' value={submitLabel} />
        </> : <ButtonLargeItem type='button' value={'닫기'} onClick={onClose} />
      }

    </FloatingButtonGroup>

  </Container>)

}

const Container = styled.div`
display: flex;
height: 100%;
flex-direction: column;
justify-content: space-betwenn;
td {
  width: fit-content;
}
`

const FloatingButtonGroup = styled.div`
background-color: #FFCE3E;
display: flex;
position: fixed;
bottom: 0;
left: 0;
width: 100%;
`

const ButtonItem = styled.input`
background-color: #FFCE3E;
cursor: pointer;
border: none;
width: 50%;
height: 40px;
border: .5px solid rgba(0,0,0,0.1);
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
`

const ButtonLargeItem = styled.input`
background-color: #FFCE3E;
cursor: pointer;
border: none;
width: 100%;
height: 40px;
border: .5px solid rgba(0,0,0,0.1);
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
`

const TdLabel = styled.div`
width: 130px;
font-weight: 500;
color: rgba(0,0,0,0.5);
`

const TdValue = styled.div`
width: 100%;
`

const Textarea = styled.textarea`
box-sizing: border-box;
width: 100%;
height: 150px;
padding: 15px;
border-radius: 5px;
background-color: rgb(235, 235, 235);
border: 0px;
outline: none;
font-size: 14px;
font-weight: 500;
text-align: left;
border: 2px solid transparent;

:focus {
  border: 2px solid rgb(0, 100, 255);
}
`

const TextareaViewer = styled.div`
white-space: pre-wrap;
`

const Img = styled.img`
max-width: 350px;
width: 100%;
`

const Input = styled.input`
text-align: left;
box-sizing: border-box;
width: fit-content;
padding: 15px;
border-radius: 5px;
background-color: rgb(235, 235, 235);
border: 0px;
outline: none;
font-size: 14px;
font-weight: 500;
text-align: left;
border: 2px solid transparent;

:focus {
  border: 2px solid rgb(0, 100, 255);
}
`

const Select = styled.select`
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
box-sizing: border-box;
width: fit-content;
padding: 15px;
border-radius: 5px;
background-color: rgb(235, 235, 235);
border: 0px;
outline: none;
font-size: 14px;
font-weight: 500;
text-align: left;
margin-right: 8px;
border: 2px solid transparent;
:focus {
  border: 2px solid rgb(0, 100, 255);
}
`