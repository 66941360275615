import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ContentLayout } from '../shared/content-layout';
import { HOST_URL } from '../shared/env';
import axios from 'axios';
import styled from '@emotion/styled';

export function FrTest() {

  const { register, handleSubmit, watch } = useForm()
  const [sourceImage, setSourceImage] = useState('')
  const [targetImage, setTargetImage] = useState('')
  const [data, setData] = useState({ compared: false, similarity: -1})

  const watchSourceImage = watch('source_image')
  const watchTargetImage = watch('target_image')

  const onSubmit = async (e) => {

    const form = new FormData()
    form.append('lang', 'ko')
    form.append('token', localStorage.getItem('admin_token'))
    form.append('source_image', e.source_image[0])
    form.append('target_image', e.target_image[0])

    const { data } = await axios({
      method: 'post',
        url: `${HOST_URL}/app/compareFace`,
      data: form,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    setData(data)

  }

  useEffect(() => {

    if (watchSourceImage) {

      setSourceImage(watchSourceImage[0])

    }

  }, [watchSourceImage])

  useEffect(() => {

    if (watchTargetImage) {

      setTargetImage(watchTargetImage[0])

    }

  }, [watchTargetImage])

  return (
    <ContentLayout 
      title='얼굴 인식 테스트' 
      description='얼굴 인식 테스트 페이지입니다'
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        
        <ImageGroup>
          <div>
            <Image src={sourceImage ? URL.createObjectURL(sourceImage) : ''} />
            <input {...register('source_image')} type='file' />
          </div>
          <div>
          <Image src={targetImage ? URL.createObjectURL(targetImage) : ''} />
            <input {...register('target_image')} type='file' />
          </div>
        </ImageGroup>

        <p />
        <button>전송</button>
      </form>

      <div>
        <h3>결과</h3>
        {(data.similarity >= 0) && 
        <div>
          {
            data.compared ?
            <p>
              일치 유사도 : {data.similarity}%
            </p>
          :
            <p>
              불일치
            </p>
          }
        </div>}
      </div>
    </ContentLayout>
  )
}

const ImageGroup = styled.div`
display: flex;
div {
  width: 50%;
}
`

const Image = styled.img`
width: 100%;
`